
import { useNavigate } from 'react-router-dom';
import Nav from '../../components/breadcrumb';
import PageHeader, { Title } from '../../components/pageheader';
import UserForm from './user-form';

const userObject = {
    other_names: "", last_name: "", email: "", telephone: "",
    roles: [], job_title: ""
}

/**
 * Create a new user.
 * @returns {JSX.Element}
 */
const NewUser = () => {

    const nav_items = [
        { title: 'Users', href: '/app/users' },
        { title: 'New User' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        navigate("/app/users");
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>Create New User</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <UserForm
                    details={userObject}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import('./view-users').UserObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('./view-users').UserObject>>} props.setDetails
 */
const EditUser = ({ details, setDetails }) => {

    const navigate = useNavigate();

    const nav_items = [
        { title: 'Users', href: '/app/users' },
        { title: details.display_name, href: `/app/users/${details.id}` },
        { title: 'Edit Details' }
    ];

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/users/${details.id}`);
    }


    return (

        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>{details.display_name}</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <UserForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewUser, EditUser };