import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Tab, Table, Tabs } from "react-bootstrap";
import { Routes, Route, useParams, Link } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import cur from "../../components/currency";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { ActionBar, ActionButton, ButtonToolbar, Title } from '../../components/pageheader';
import { sortFunction, UCWords } from "../../components/resources";
import { getAccount } from "../../resources/api/accounts";
import { TransferForm } from "./accounts-form";
import { AppUserContext } from "../../App";

const form_defaults = {
    amount: "",
    direction: "out",
    from_account_id: "",
    to_account_id: "",
    transfer_date: ""
}

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/accounts").AccountObject} props.details
 * @param {(details: import("../../resources/api/accounts").AccountTransferObject, action: "edit"|"create")=>void} props.onTransferUpload
 */
const ViewDetails = ({ details, onTransferUpload }) => {

    const nav_items = [
        { title: 'Accounts', href: "/app/accounts" },
        { title: details.title }
    ]

    const [show, setShow] = useState(false);
    const [edit_action, setEditAction] = useState("create");

    const [transfer_details, setTransferDetails] = useState(form_defaults);

    const onClickNewIn = () => {
        setTransferDetails({ ...form_defaults, direction: "in", to_account_id: details.id });
        setEditAction("create");
        setShow(true);
    }

    const onClickNewOut = () => {
        setTransferDetails({ ...form_defaults, direction: "out", from_account_id: details.id });
        setEditAction("create");
        setShow(true);
    }

    const [key, setKey] = useState('satement');


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.title}</Title>

                <ActionBar>
                    <ButtonToolbar>
                        <ActionButton onClick={onClickNewIn} title="Inward Transfer">
                            <i className='fas fa-arrow-right-to-bracket' />
                        </ActionButton>
                        <ActionButton onClick={onClickNewOut} title="Outward Transfer">
                            <i className='fas fa-arrow-right-from-bracket' />
                        </ActionButton>
                    </ButtonToolbar>
                </ActionBar>

            </PageHeader>

            <div className="max-1000">

                <dl style={{ columnCount: 3 }}>
                    <dt>Opening Balance</dt>
                    <dd>{cur(details.opening_balance, 0).format()}</dd>
                    <dt>Available Balance</dt>
                    <dd>{cur(details.available_balance, 0).format()}</dd>
                    <dt>Status</dt>
                    <dd>{details.isActive ? "Active" : "Inactive"}</dd>
                </dl>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >

                    <Tab eventKey="satement" title="Statement">

                        <Table responsive style={{ minWidth: "700px" }} hover size="sm">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Reference</th>
                                    <th>Date</th>
                                    <th>Credit</th>
                                    <th>Debit</th>
                                    <th>Running Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.transactions.map(t => (
                                    <tr key={t.ref} >
                                        <td>{UCWords(t.type)}</td>
                                        <td>
                                            {(t.type !== "transfer") ?
                                                <Link to={t.type === "expense" ? `/app/expenses/${t.id}` : `/app/payments/${t.id}`}>
                                                    {t.ref}
                                                </Link>
                                                :
                                                t.ref
                                            }

                                        </td>
                                        <td>{moment(t.date).format("DD MMM YYYY")}</td>
                                        <td>{cur(t.credit, 0).format()}</td>
                                        <td>{cur(t.debit, 0).format()}</td>
                                        <td>{cur(t.running_balance, 0).format()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="transfers" title="Transfers">

                        <Table style={{ maxWidth: "550px" }} hover size="sm">
                            <thead>
                                <tr >
                                    <th>Date</th>
                                    <th>Direction</th>
                                    <th>Account</th>
                                    <th className="text-end">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.transfers
                                    .map(p => (
                                        <tr key={p.id} >
                                            <td>{moment(p.transfer_date).format("DD MMM YYYY")}</td>
                                            <td>{p.direction.toUpperCase()}</td>
                                            <td>{(p.direction === "in") ? p.from_account : p.to_account}</td>
                                            <td className="text-end">{cur(p.amount, 0).format()}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </Tab>
                </Tabs>

            </div>

            <TransferForm
                transferDetails={{ details: transfer_details, setDetails: setTransferDetails }}
                action={edit_action}
                show={{ show, setShow }}
                direction={transfer_details.direction}
                onUpload={onTransferUpload}
            />

        </>
    )

}


const AccountDetails = () => {

    const { accountid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    const { profile } = useContext(AppUserContext);

    const organiseTransactions = (transactions, o_bal = 0) => {

        transactions.sort((a, b) => sortFunction(a, b, "date", "asc"));

        let prev_balance = parseFloat(o_bal);

        return transactions.map(t => {
            prev_balance += (t.credit - t.debit);
            return {
                ...t,
                running_balance: prev_balance
            };
        })
    }

    useEffect(() => {

        setLoaded(false);
        setError();

        getAccount(accountid, ['transfers', 'expenses', 'payments'])
            .then(({ account }) => {
                // staff.payments = staff.payments.sort((a, b) => sortFunction(a, b, "date_added"));
                let transactions = [];
                account.expenses.forEach(e => {
                    transactions.push({
                        id: e.id,
                        type: "expense",
                        ref: e.reference,
                        debit: parseFloat(e.total_amount),
                        credit: 0,
                        date: e.expense_date
                    })
                })

                account.payments.forEach(e => {
                    transactions.push({
                        id: e.id,
                        type: "payment",
                        ref: e.receipt_no,
                        credit: parseFloat(e.total_amount),
                        debit: 0,
                        date: e.payment_date
                    })
                })

                account.transfers.forEach(e => {
                    transactions.push({
                        id: e.id,
                        type: "transfer",
                        ref: (e.direction === "in") ? e.from_account : e.to_account,
                        credit: (e.direction === "in") ? parseFloat(e.amount) : 0,
                        debit: (e.direction === "out") ? parseFloat(e.amount) : 0,
                        date: e.transfer_date
                    })
                })

                transactions = organiseTransactions(transactions, account.opening_balance);

                let transfers = account.transfers.sort((a, b) => sortFunction(a, b, 'transfer_date', "desc"));

                setDetails({ ...account, transfers, transactions });
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [accountid])

    const handleTransferUpload = (transfer, action = "edit") => {
        if (action === "edit") {
            let transactions = [...details.transactions],
                transfers = [...details.transfers];

            transactions.splice(
                transactions.findIndex(i => i.id === transfer.id),
                1,
                {
                    id: transfer.id,
                    type: "transfer",
                    ref: (transfer.direction === "in") ? transfer.from_account : transfer.to_account,
                    credit: (transfer.direction === "in") ? parseFloat(transfer.amount) : 0,
                    debit: (transfer.direction === "out") ? parseFloat(transfer.amount) : 0,
                    date: transfer.transfer_date
                }
            );

            transactions = organiseTransactions(transactions, details.opening_balance);

            transfers.splice(
                transfers.findIndex(i => i.id === transfer.id),
                1,
                transfer
            );

            setDetails(d => ({ ...d, transactions, transfers }));

        } else {

            let transactions = [...details.transactions],
                transfers = [...details.transfers];

            transactions = transactions.concat({
                id: transfer.id,
                type: "transfer",
                ref: (transfer.direction === "in") ? transfer.from_account : transfer.to_account,
                credit: (transfer.direction === "in") ? parseFloat(transfer.amount) : 0,
                debit: (transfer.direction === "out") ? parseFloat(transfer.amount) : 0,
                date: transfer.transfer_date
            })

            transactions = organiseTransactions(transactions, details.opening_balance);
            transfers = transfers.concat(transfer).sort((a, b) => sortFunction(a, b, 'transfer_date', "desc"));

            setDetails(d => ({ ...d, transfers, transactions }));
        }
    }

    if (profile.permission_level > 1) return <ErrorLoading>You do not have the permission to view this page</ErrorLoading>

    if (!isLoaded) return <PageLoading>Loading account details...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>

    return (
        <Routes>
            <Route path="/" element={<ViewDetails details={details} onTransferUpload={handleTransferUpload} />} />
        </Routes>
    )

}


export default AccountDetails;