import Nav from '../../components/breadcrumb';
import PageHeader, { Title } from '../../components/pageheader';
import { useNavigate } from 'react-router-dom';
import InventoryForm from './inventory-form';

const itemObject = {
    name: "", description: "", category: "", opening_units: "0", unit: ""
}

const NewInventory = () => {

    const nav_items = [
        { title: 'Inventory', href: '/app/inventory' },
        { title: 'New Item' }
    ];

    // const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const handleSubmit = (details) => {

        // const action = searchParams.get('action');

        // if (action === "create_event") {
        //     navigate(`/app/events/new?client=${details.id}`);
        // } else {
        //     navigate(`/app/clients/${details.id}`);
        // }

        navigate(`/app/inventory/${details.id}`);
    }


    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <InventoryForm
                    details={itemObject}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/inventory').InventoryItem} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/inventory').InventoryItem>>} props.setDetails 
 */
const EditInventory = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Inventory', href: '/app/inventory' },
        { title: details.name, href: `/app/inventory/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/inventory/${details.id}`);
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>{details.name}</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <InventoryForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewInventory, EditInventory };