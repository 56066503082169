
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Nav from '../../components/breadcrumb';
import PageHeader, { Title } from '../../components/pageheader';
import { DateSelector } from "../../components/datetimepicker";
// import SelectInput from "../../components/select";
import { sortFunction } from "../../components/resources";
import { generateStatement, printStatement } from "../../resources/api/clients";
import { errorAlert, infoAlert } from "../../components/toastr";
import cur from "../../components/currency";
import moment from "moment";
import { APIURL } from "../../resources/fetch";


const { Check, Label, Control } = Form;

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/clients").ClientObject} props.details
 */
const ClientStatement = ({ details }) => {

    const nav_items = [
        { title: 'Clients', href: "/app/clients" },
        { title: details.title, href: `/app/clients/${details.id}` },
        { title: 'Generate Statement' }
    ]

    const [date_range, setDateRange] = useState({ from: "", to: "" })
    const [checked, setChecked] = useState({ executed: true, printAll: false });

    const [original_items, setOriginalItems] = useState([]);
    const [items, setItems] = useState([]);
    const [deleted_items, setDeletedItems] = useState([]);


    useEffect(() => {

        generateStatement(details.id)
            .then(({ items }) => {

                let
                    today = moment().format("YYYY-MM-DD"),
                    __items = [],
                    _items = items
                        .filter(i => ((i.due_amount - i.payment_amount) > 0))
                        .map(i => ({ ...i, comments: "" }));

                _items.sort((a, b) => sortFunction(a, b, "job_date", "asc"));
                __items = _items.filter(i => (i.job_date <= today));

                setOriginalItems(_items);
                setItems(__items);
            })
            .catch(errorAlert)

    }, [details.id])


    const onReset = () => {
        setItems(original_items);
        setDateRange({ from: "", to: "" });
        setChecked({ executed: true, printAll: false });
        setDeletedItems([]);
    }

    const onFilter = () => {
        let _items = [...original_items].filter(i => (deleted_items.indexOf(i.long_id) === -1)),
            today = moment().format("YYYY-MM-DD");

        if (!!date_range.from)
            _items = _items.filter(i => i.job_date >= date_range.from);


        if (!!date_range.to)
            _items = _items.filter(i => i.job_date <= date_range.to);

        if (checked.executed)
            _items = _items.filter(i => (i.job_date <= today && i.job_status === "confirmed"));
        else if (checked.confirmed)
            _items = _items.filter(i => (i.job_status === "confirmed"));

        setItems(_items);
    }

    const updateComment = (text, id) => {
        let _items = [...items],
            idx = _items.findIndex(i => i.id === id),
            _item = _items[idx];

        _item.comments = text;
        _items.splice(idx, 1, _item);
        setItems(_items);
    }

    const onGenerateClick = () => {

        let data = items.map(i => ({ id: i.long_id, comments: i.comments }));

        infoAlert("Printing statement ...");

        printStatement(data)
            .then(() => {
                let _link = `${APIURL}/clients/${details.id}/statement/pdf`;

                if (checked.printAll) _link += `?printAll=1`;

                window.open(_link);
            })
            .catch(errorAlert)
    }

    const onDeleteClick = id => {

        setDeletedItems(i => i.concat(id));
        setItems(_items => _items.filter(i => i.long_id !== id));
    }



    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>Generate {details.title}'s statement</Title>

            </PageHeader>

            <div className="max-1000">

                <Row xs={{ cols: "auto" }} className="align-items-center flex-column flex-sm-row mb-3 g-2">
                    <Col>
                        <Label className="form-field-label">From Date</Label>
                        <DateSelector
                            size="sm"
                            value={date_range.from}
                            onChange={d => setDateRange(dates => ({ ...dates, from: d }))}
                        />
                    </Col>
                    <Col className="border-start">
                        <Label className="form-field-label">To Date</Label>
                        <DateSelector
                            size="sm"
                            value={date_range.to}
                            onChange={d => setDateRange(dates => ({ ...dates, to: d }))}
                        />
                    </Col>
                    <Col className="border-start">
                        <Check
                            size="sm"
                            type="checkbox"
                            id={`executedonly`}
                            label={`Jobs must be executed`}
                            checked={checked.executed}
                            onChange={() => setChecked(c => ({ ...c, executed: !c.executed }))}
                        />
                    </Col>
                </Row>
                <Row className="g-1">
                    <Col>
                        <Button className="rounded-pill mx-1" variant="danger" size="sm" onClick={onReset}>
                            <i className="fas fa-undo me-2" />
                            Reset
                        </Button>
                        <Button className="rounded-pill mx-1" variant="success" size="sm" onClick={onFilter}>
                            <i className="fas fa-search me-2" />
                            Run Filter
                        </Button>
                    </Col>
                </Row>

                <Table className="mt-3 mt-sm-5" responsive>
                    <colgroup>
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "30%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Event</th>
                            <th>Order</th>
                            <th>Tax Invoice</th>
                            <th>Due</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => (
                            <tr key={item.id}>
                                <td>
                                    <b>{item.job} ({item.job_no}) </b><br />
                                    Date: {moment(item.job_date).format("DD MMMM YYYY")} <br />
                                    Contact: {item.other_names} {item.last_name} <br />
                                    <Button variant="link" className="rounded-circle text-danger px-0" size="sm" onClick={() => onDeleteClick(item.long_id)}>
                                        <i className="fas fa-trash-alt me-1" />Delete
                                    </Button>
                                </td>
                                <td>
                                    Quote No: {item.invoice_no.replace(/[^0-9-]/g, "")} <br />
                                    {!!item.lpo_no ?
                                        <>
                                            LPO Order No: {item.lpo_no} received on {moment(item.lpo_date).format("DD MMM YYYY")} <br />
                                        </> :
                                        <>
                                            LPO not issued <br />
                                        </>
                                    }
                                </td>
                                <td>
                                    {!!item.effris_date ?
                                        <>
                                            Effris Date: {moment(item.effris_date).format("DD MMMM YYYY")} <br />
                                            Effris FDN: {item.effris_fdn}<br />
                                            Effris VC: {item.effris_vc}
                                        </> :
                                        <>
                                            Tax Invoice not issued <br />
                                        </>
                                    }
                                </td>
                                <td className="text-end">{cur(item.due_amount - item.payment_amount, 0).format()}</td>
                                <td>
                                    <Control as="textarea" size="sm" value={item.comments} onChange={e => updateComment(e.currentTarget.value, item.id)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th className="text-end">
                                {
                                    cur(
                                        items.reduce((p, c) => (p + (c.due_amount - c.payment_amount)), 0),
                                        0
                                    ).format()
                                }
                            </th>
                            <th></th>

                        </tr>
                    </tfoot>

                </Table>

                <Row className="g-1">
                    <Col>
                        <Check
                            size="sm"
                            type="checkbox"
                            id={`printAll`}
                            label={`Include invoices in statements`}
                            checked={checked.printAll}
                            onChange={() => setChecked(c => ({ ...c, printAll: !c.printAll }))}
                        /></Col>
                </Row>



                <Row className="g-1">
                    <Col>
                        <Button className="rounded-pill" variant="secondary" onClick={onGenerateClick}>
                            <i className="fas fa-file-pdf me-2" />
                            Generate Report
                        </Button>
                    </Col>
                </Row>
            </div>

        </>
    )

}




export default ClientStatement;