import fetchData, { APIURL } from "../fetch";



const getFinances = () => fetchData({
    url: `${APIURL}/dashboard/finances`
});




export {
    getFinances
}