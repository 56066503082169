import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Routes, Route, useParams, Link } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import cur from "../../components/currency";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { ConfirmAction } from "../../components/prompt";
import { UCWords, sortFunction, } from "../../components/resources";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import { deleteClientContact, getClient } from "../../resources/api/clients";
import { EditClient } from "./client-manipulation";
import ContactPersonForm from "./contact-person-form";
import ViewStatement from "./statement";
import ClientStatement from "./client-statement";


const ContactPersonObject = {
    other_names: "", last_name: "", email: "", telephone: ""
}

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/clients").ClientObject} props.details
 * @param {React.Dispatch<React.SetStateAction<import("../../resources/api/clients").ClientObject>>} props.setDetails
 */
const ViewDetails = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Clients', href: "/app/clients" },
        { title: details.title }
    ]

    const [formShow, setFormShow] = useState(false);
    const [form_details, setFormDetails] = useState(ContactPersonObject);
    const [formAction, setFormAction] = useState("create");

    /**
     * open form to create new contact person
     */
    const createNewContactPerson = () => {
        setFormDetails(ContactPersonObject);
        setFormAction("create");
        setFormShow(true);
    }

    /**
     * On Click of edit contct person, open the form
     * @param {string} id 
     * @param {React.MouseEvent} e 
     */
    const editContactPerson = (id, e) => {
        e.preventDefault();

        const item = details.contact_persons.find(i => (i.id === id));

        setFormDetails(item);
        setFormAction("edit");
        setFormShow(true);
    }

    /**
     * Add new contact person to the list
     * @param {import("../../resources/api/clients").ContactPersonObject} details 
     */
    const handleContactCreate = contact_person => {
        setDetails(d => ({ ...d, contact_persons: d.contact_persons.concat(contact_person) }));
    }

    /**
     * Update list after API call
     * @param {import("../../resources/api/clients").ContactPersonObject} details 
     */
    const handleContactUpdate = (contact_person) => {
        let contact_persons = [...details.contact_persons];
        contact_persons.splice(
            contact_persons.findIndex(c => (c.id === contact_person.id)),
            1,
            contact_person
        );

        setDetails(d => ({ ...d, contact_persons }));

    }

    /**
     * Delete a contact person
     * @param {string} id 
     */
    const deleteContactPerson = (id) => {
        infoAlert("Deleting contact person...");
        deleteClientContact(id)
            .then(({ message }) => {
                successAlert(message);
                setDetails(d => ({
                    ...d,
                    contact_persons: d.contact_persons.filter(c => (c.id !== id))
                }));
            })
            .catch(errorAlert)
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.title}</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn href={`/app/clients/${details.id}/generate-statement`} title="Generate Statement">
                            <i className="fas fa-newspaper" />
                        </Btn>
                        <Btn href={`/app/clients/${details.id}/statement`} title="View Transactions">
                            <i className="fas fa-file-lines" />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href={`/app/invoices/new?client=${details.id}`} title="New Quotation">
                            <i className="fas fa-file-invoice" />
                        </Btn>
                        <Btn href={`/app/jobs/new?client=${details.id}`} title="New Client Job">
                            <i className="fas fa-calendar-plus" />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href={`/app/clients/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                        <Btn href="/app/clients/new" title="New Client">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <h4>Client Details</h4>
                <dl>
                    {/* <dt>Email</dt> */}
                    {/* <dd>
                        {details.email ?
                            <a href={`mailto:${details.email}`}>{details.email}</a> :
                            "n/a"}
                    </dd> */}
                    {/* <dt>Telephone</dt>
                    <dd>
                        {details.telephone ?
                            <a href={`tel:${details.telephone}`}>{details.telephone}</a> :
                            "n/a"}
                    </dd>
                    <dt>Telephone</dt>
                    <dd>{details.address || 'n/a'}</dd> */}
                    <dt>No Jobs</dt>
                    <dd>{details.no_jobs}</dd>
                    {/* <dt>Total Revenue</dt>
                    <dd>{UGX(details.total_revenue).format()}</dd> */}
                    <dt>
                        Contact Persons
                        <Button
                            variant="link"
                            size="sm"
                            className="ms-2 text-secondary"
                            onClick={createNewContactPerson}
                        >
                            <i className="fas fa-circle-plus" />
                        </Button>
                    </dt>
                    <dd>
                        {(details.contact_persons.length === 0) && <>No contact Persons</>}
                        {details.contact_persons.map(p => (
                            <span className="d-block" key={p.id}>
                                {p.display_name} | T:{p.telephone} {!!p.email && ` | E: ${p.email}`}
                                <a href="#." className="ms-1 ms-sm-2 px-1 small text-primary" onClick={(e) => editContactPerson(p.id, e)}>
                                    <i className="fas fa-pencil-alt" />
                                </a>
                                <ConfirmAction
                                    as="a"
                                    href="#."
                                    className="ms-1 px-1 small text-danger"
                                    confirmationText="Are you sure you want to delete this contact person?"
                                    handleAction={() => deleteContactPerson(p.id)}
                                >
                                    <i className="fas fa-trash-alt" />
                                </ConfirmAction>
                            </span>
                        ))}
                    </dd>
                </dl>

                <ViewJobs jobs={details.jobs} />

                <ContactPersonForm
                    clientId={details.id}
                    action={formAction}
                    details={{ details: form_details, setDetails: setFormDetails }}
                    onCreate={(formAction === "edit") ? handleContactUpdate : handleContactCreate}
                    toShow={{ show: formShow, setShow: setFormShow }}

                />

            </div>

        </>
    )

}


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/jobs").JobObject[]} props.jobs 
 * @returns 
 */
const ViewJobs = ({ jobs }) => {

    if (jobs.length === 0) return <></>

    return (
        <>
            <h4 className="mt-3 mt-md-5">Jobs</h4>
            <Table responsive hover size="sm" style={{ maxWidth: "800px", minWidth: "500px" }}>
                <thead>
                    <tr>
                        <th>Job</th>
                        <th>Date</th>
                        <th>Event</th>
                        <th>Location</th>
                        <th className="text-end">Total Amount</th>
                        <th className="text-end">Balance</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {jobs.filter(j => j.status !== "cancelled").map(e => (
                        <tr key={e.id}>
                            <td>
                                <Link to={`/app/jobs/${e.id}`} >{e.job_no}</Link>
                            </td>
                            <td>{moment(e.job_date).format("DD MMM YYYY")}</td>
                            <td>{e.title}</td>
                            <td>{e.location}</td>
                            <td className="text-end">{cur(e.total_amount, 0).format()}</td>
                            <td className="text-end">{cur(e.balance, 0).format()}</td>
                            <td>{UCWords(e.status?.replace(/-/g, " "))}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={4}>Total</th>
                        <th className="text-end">{cur(jobs.reduce((p, c) => p + c.total_amount, 0), 0).format()}</th>
                        <th className="text-end">{cur(jobs.reduce((p, c) => p + c.balance, 0), 0).format()}</th>
                        <th />
                    </tr>
                </tfoot>
            </Table>
        </>
    )
}



const ClientDetails = () => {

    const { clientid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();


    useEffect(() => {

        setLoaded(false);
        setError(null);

        const sortFn = (a, b) => sortFunction(a, b, 'job_date', "asc");

        getClient(clientid, ['jobs', 'contact_persons'])
            .then(({ client }) => {

                const jobs = client.jobs.filter(j => (j.status !== 'cancelled'));
                jobs.sort(sortFn);

                setDetails({ ...client, jobs });
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [clientid])

    //load events details

    if (!isLoaded) {
        return <PageLoading />
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <Routes>
            <Route path="/generate-statement" element={<ClientStatement details={details} />} />
            <Route path="/statement" element={<ViewStatement details={details} />} />
            <Route path="/edit" element={<EditClient details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewDetails details={details} setDetails={setDetails} />} />
        </Routes>
    )

}


export default ClientDetails;