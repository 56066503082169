
import React, { useEffect, useState } from "react";
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title } from '../../components/pageheader';
import { updateStorePackingList } from "../../resources/api/jobs";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";

import { useNavigate } from "react-router-dom";
import { getInventory } from "../../resources/api/inventory";
import { Select } from "../../components/select";
import { CancelButton, SubmitButton } from "../../components/btns";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/jobs").JobObject} props.details
 * @param {import("../../resources/api/jobs").PackingListObject[]} props.items
 * @param {(job: import("../../resources/api/jobs").PackingListObject[]) => void} props.setItems
 */
const EditPackingList = ({ details, items: PLItems, setItems: updateItems }) => {

    const nav_items = [
        { title: 'Jobs', href: "/app/jobs" },
        { title: details.job_no, href: `/app/jobs/${details.id}` },
        { title: "Packing List", href: `/app/jobs/${details.id}/packing-list` },
        { title: "Update" }
    ]

    const [items, setItems] = useState(PLItems);
    const [selected_ids, setSelectedIDs] = useState(PLItems.map(i => i.item_id));
    const [deleted_items, setDeletedItems] = useState([]);

    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const [inventory, setInventory] = useState([]);

    const navigate = useNavigate();


    const addItem = (ids) => {

        const new_items = inventory
            .filter(i => (ids.indexOf(i.id) !== -1))
            .map(i => ({ name: i.name, item_id: i.id, job_id: details.id, isNew: 1, quantity: "", comments: "" }));
        setItems(items.concat(new_items));
        setSelectedIDs(selected_ids.concat(ids));
    }


    /**
     * Update an item in the list
     * @param {number} index 
     * @param {import('../resources/api/invoices').InvoiceItemObject} value 
     */
    const updateItem = (index, value) => {
        let current_items = [...items];
        value.hasChanged = true;
        current_items.splice(index, 1, value);
        setItems(current_items);
    }

    /**
     * Deletes an item in poisition index from the list.
     * @param {number} index Index of item in the list
     */
    const deleteItem = index => {
        if (items.length < 2) return errorAlert("You must have at least one list item.");

        const _item = { ...items[index] };

        if (_item.id) setDeletedItems(items => items.concat(_item.id));

        setItems(items
            .filter((e, i) => i !== index)
        );

        setSelectedIDs(selected_ids.filter(id => (id !== _item.item_id)));
        infoAlert("Packing List item has been deleted.");
    }


    useEffect(() => {

        setError(null);
        setLoaded(false);

        getInventory()
            .then(({ inventory }) => {
                setInventory(inventory.map(i => ({ ...i, title: i.name })));
            })
            .catch(setError)
            .finally(() => setLoaded(true))

    }, [])


    /**
 * handle the overall submitting of the form
 * @param {React.FormEvent} e
 */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        let promise = updateStorePackingList({
            new_items: items.filter(i => i.isNew),
            updated_items: items.filter(i => (!!i.id && i.hasChanged)),
            deleted_items,
        }, details.id);


        promise
            .then(({ items, message }) => {
                successAlert(message);
                updateItems(items);
                navigate(`/app/jobs/${details.id}/packing-list`)
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }




    if (!isLoaded) {
        return <PageLoading>Loading inventory details...</PageLoading>
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.title} - Packing List</Title>
            </PageHeader>

            <div className="max-1000">

                <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
                    <Row className="d-none d-sm-flex">
                        <Col sm={4} className="py-0">
                            <Form.Label className="form-field-title">Title</Form.Label>
                        </Col>
                        <Col sm={3} className="py-0">
                            <Form.Label className="form-field-title">Quantity</Form.Label>
                        </Col>
                        <Col sm={5} className="py-0">
                            <Form.Label className="form-field-title">Comments</Form.Label>
                        </Col>
                    </Row>
                    {items.map((e, i) => <ListItem
                        key={i} details={e}
                        onChange={value => updateItem(i, value)}
                        onDelete={() => deleteItem(i)}
                    />)}

                    <Select
                        size="sm"
                        className='rounded-pill'
                        items={inventory.filter(inv => (selected_ids.indexOf(inv.id) === -1))}
                        maxItems={20}
                        onSelect={addItem}
                    >
                        <i className='fas fa-store me-1' />Add Item
                    </Select>


                    <Row>
                        <Col className="mt-3 mt-sm-5 mb-3 text-end">
                            <SubmitButton isSubmitting={isSubmitting} type="submit">
                                Update List
                            </SubmitButton>
                            <CancelButton isSubmitting={isSubmitting} onClick={() => navigate(-1)}>
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )

}


/**
 * 
 * @param {Object} props
 * @param {import('../resources/api/invoices').InvoiceItemObject} props.details
 * @param {(item: import('../resources/api/invoices').InvoiceItemObject) => void } props.onChange
 * @param {(index: number) => void } props.onDelete
 */
const ListItem = ({ details, onChange, onDelete }) => {

    const { Label, Control } = Form;

    return (
        <div className="my-3 pb-1 pb-sm-0 my-sm-1 border-bottom">
            <Row className="label-sm-hide g-1">
                <Col sm={4} className="my-1">
                    <div>
                        <Label className="form-field-title">Title</Label>
                        <Control
                            size="sm"
                            value={details.name}
                            disabled
                        />
                    </div>
                </Col>
                <Col sm={3} className="my-1">
                    <Label className="form-field-title">Qty</Label>
                    <Control
                        size="sm"
                        value={details.quantity}
                        onChange={e => onChange(({ ...details, quantity: e.currentTarget.value }))}
                        type="number"
                        required
                        min="1"
                    />
                    <Control.Feedback type="invalid">
                        Quantity must not be less than 1.
                    </Control.Feedback>
                </Col>
                <Col xs={5} sm={5} className="my-1">
                    <Label className="form-field-title">Comment</Label>
                    <InputGroup>
                        <Control
                            size="sm"
                            value={details.comments}
                            onChange={e => onChange(({ ...details, comments: e.currentTarget.value }))}
                            as="textarea"
                            rows={1}
                            onFocus={e => e.currentTarget.rows = 3}
                            onBlur={e => e.currentTarget.rows = 1}

                        />
                        <Button className="text-danger" variant="link" onClick={onDelete} size="sm">
                            <i className="fas fa-times" />
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )
}






export default EditPackingList;