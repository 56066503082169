// import moment from "moment";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Routes, Route, useParams, Link } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import { UGX } from "../../components/currency";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { activateItem, dectivateItem, getItem } from "../../resources/api/pricelist";
import { EditPricelistItem } from "./pricelist-manipulation";
import { UCWords } from "../../components/resources";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/pricelist").PricelistObject} props.details
 */
const ViewDetails = ({ details, onChangeActivation }) => {

    const nav_items = [
        { title: 'Price List', href: "/app/pricelist" },
        { title: details.name }
    ]

    const deactivate = () => {
        infoAlert("Deactivating item...");

        dectivateItem(details.id)
            .then(({ message }) => {
                successAlert(message);
                onChangeActivation(false);
            })
            .catch(errorAlert)
    }


    const activate = () => {
        infoAlert("Activating item...");

        activateItem(details.id)
            .then(({ message }) => {
                successAlert(message);
                onChangeActivation(true);
            })
            .catch(errorAlert)
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.name}</Title>
                <ActionBar>
                    <ButtonToolbar >
                        {!details.isActive &&
                            <Btn title="Activate" onClick={activate}>
                                <i className="fas fa-check-circle" />
                            </Btn>
                        }
                        {details.isActive &&
                            <Btn title="Deactivate" onClick={deactivate}>
                                <i className="fas fa-ban" />
                            </Btn>
                        }
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href={`/app/pricelist/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                        <Btn href="/app/pricelist/new" title="New Item">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <h4>Details</h4>
                <dl>
                    <dt>Category</dt>
                    <dd>{UCWords(details.category.replace(/-/g, " "))}</dd>
                    {/* <dt>Component Count</dt>
                    <dd>{details.no_components}</dd> */}
                    <dt>Description</dt>
                    <dd>{details.description}</dd>
                    <dt>Price</dt>
                    <dd>{UGX(details.price).format()}</dd>
                    <dt>Status</dt>
                    <dd>{details.isActive ? "Active" : "Inactive"}</dd>
                    {/* <dt>Total Revenue</dt>
                    <dd>{UGX(details.total_revenue).format()}</dd> */}
                </dl>

                <ViewComponents components={details.inventory} />
            </div>

        </>
    )

}


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/pricelist").PricelistItemObject[]} props.components 
 * @returns 
 */
const ViewComponents = ({ components }) => {

    if (components.length === 0) return <></>

    return (
        <>
            <h4 className="mt-3 mt-md-5">Components</h4>
            <Table responsive hover size="sm" style={{ maxWidth: "350px", minWidth: "350px" }}>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th className="text-center">Quantity</th>
                        {/* <th>Date Added</th>
                        <th>Date Updated</th> */}
                    </tr>
                </thead>
                <tbody>
                    {components.map(e => (
                        <tr key={e.id}>
                            <td><Link to={`/app/inventory/${e.item_id}`} >{e.item}</Link></td>
                            <td className="text-center">{e.quantity}</td>
                            {/* <td>{moment(e.date_added).format("DD MMM YYYY")}</td>
                            <td>{moment(e.date_updated).format("DD MMM YYYY")}</td> */}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}


const PricelistItemDetails = () => {

    const { itemid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();


    useEffect(() => {

        setLoaded(false);
        setError(null);


        getItem(itemid, ['inventory'])
            .then(({ item }) => {

                setDetails(item);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [itemid])

    const changeActiveStatus = status => setDetails(d => ({ ...d, isActive: status }))

    //load events details

    if (!isLoaded) {
        return <PageLoading />
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <Routes>
            <Route path="/edit" element={<EditPricelistItem details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewDetails details={details} onChangeActivation={changeActiveStatus} />} />
        </Routes>
    )

}


export default PricelistItemDetails;