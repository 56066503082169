import { Form, Button, Modal, Row, Col, InputGroup as Group } from 'react-bootstrap';
import { useState, useEffect, useRef, createElement } from 'react';
// import { sortFunction } from './resources';

import { CancelButton, SubmitButton } from '../../components/btns';
import { clientObject } from './client-manipulation';
import { createClient, getClients } from '../../resources/api/clients';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { ContactPersons } from './contact-person-form';
import { Select } from '../../components/select';

const itemStyle = {
    whiteSpace: 'nowrap',
    padding: '.05rem .6rem',
    margin: '.25rem .25rem',
    display: 'inline-block',
    backgroundColor: 'var(--bs-secondary)',
    fontSize: '.8rem',
    borderRadius: '2rem'
}

/**
 * 
 * @param {{
 * as: React.ReactElement
 * onUpload: (import("../../resources/api/clients").ClientObject) => void
 * variant: string
 * size: string 
 * }} props 
 */
const ClientUpload = ({ as = Button, onUpload, variant, size, children, className = "", ...props }) => {

    const { Control, Control: { Feedback }, Label } = Form;

    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const [details, setDetails] = useState(clientObject);
    const [items, setItems] = useState([{ other_names: "", last_name: "", email: "", telephone: "" }])


    const ref = useRef(null);


    const handleExit = () => {
        setValidated(false);
        setDetails(clientObject);
        setItems([{ other_names: "", last_name: "", email: "", telephone: "" }]);
    }

    const handleSubmit = e => {

        e.preventDefault();
        const _form = ref.current;

        if (!_form.checkValidity()) {
            errorAlert("Oops! There are some errors in your form. These have been highlighted for you.");
            setValidated(true);
            setSubmitting(false);
            return;
        }

        setValidated(false);
        setSubmitting(true);

        infoAlert("Upload Client Details...");


        createClient(({ ...details, contact_persons: items }))
            .then(({ client, message }) => {
                setShow(false);
                onUpload(client);
                successAlert(message);
            })
            .catch(e => { })
    }


    return (
        <>
            {
                createElement(as, {
                    ...props,
                    children,
                    variant: variant || "secondary",
                    className,
                    size: size || "",
                    onClick: () => setShow(true)
                })
            }

            <Modal show={show} ref={ref} onExit={handleExit} centered size="lg">
                <Modal.Body>
                    <Form noValidate validated={validated} ref={ref}>

                        {/* <h4 className="form-section-label">Details</h4> */}
                        <Row>
                            <Col sm={12} className="my-1">
                                <Label className="form-field-title">Title</Label>
                                <Control
                                    value={details.title}
                                    onChange={e => setDetails({ ...details, title: e.currentTarget.value })}
                                    required={true}
                                />
                                <Feedback type="invalid">
                                    The title is required.
                                </Feedback>
                                <Form.Text muted>
                                    This can be the client's full name or company/organisation title.
                                </Form.Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12} className="my-1">
                                <Label className="form-field-title">Address</Label>
                                <Control
                                    value={details.address}
                                    onChange={e => setDetails({ ...details, address: e.currentTarget.value })}
                                    required
                                />
                                <Feedback type="invalid">
                                    Client address is required
                                </Feedback>
                            </Col>
                        </Row>


                        <h4 className="form-section-label mt-5">Contact Persons</h4>
                        <ContactPersons
                            contactpersons={{ items, setItems }}
                        />

                        <Row>
                            <Col className="mt-4 mb-3 text-end">
                                <SubmitButton isSubmitting={isSubmitting} onClick={handleSubmit}>
                                    Create Client
                                </SubmitButton>
                                <CancelButton isSubmitting={isSubmitting} onClick={() => setShow(false)}>
                                    Cancel
                                </CancelButton>
                            </Col>
                        </Row>
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}



/**
 *
 * @param {{
 * onChange: (client_ids: string[]) => void
 * value: string[]
 * allowUpload: boolean
 * required: false
 * errorText: string
 * placeholder: string
 * clients: import('../../resources/api/clients').ClientObject[]
 * maxClients: number
 * downloadClients: boolean
 * }} props
 */
const ClientInput = ({ maxClients = 1,
    onChange,
    value = [], allowUpload = true,
    required = false, errorText,
    placeholder,
    clients: importClients = [],
    downloadClients = false
}) => {

    const { Control: { Feedback } } = Form;

    const values = value.filter(i => i);

    const [clients, setClients] = useState(importClients);

    useEffect(() => {

        if (!downloadClients) return;

        infoAlert("Loading clients...");

        getClients()
            .then(({ clients }) => {
                setClients(clients.map(c => ({ ...c, description: c.address })))
                successAlert("Loaded all clients.");
            })
            .catch(e => errorAlert(`Failed to get clients from server. Error: ${e}`))

    }, [downloadClients])


    /**
     * handle the upload of a client. 
     * set it as selected value or among selected values.
     * @param {import('../../resources/api/clients').ClientObject} client 
     */
    const handleClientUpload = client => {
        if (maxClients === 1) {
            onChange([client.id]);
        } else {
            onChange([...value, client.id]);
        }
        setClients(_clients => [..._clients, { ...client, description: client.address }]);
    }

    /**
     * Remove a client from the selected list
     * @param {React.MouseEvent} e 
     * @param {string} clientid 
     */
    const removeClient = (e, clientid) => {
        e.preventDefault();
        onChange(values.filter(i => i !== clientid));
    }

    /**
     * On hit the delete key in the input field, remove item there.
     * @param {React.KeyboardEvent} e 
     */
    const handleKeyUp = e => {

        let isBackspace = false;

        if ('key' in e) {
            isBackspace = (e.key === 'Backspace');
        } else if ('keyCode' in e) {
            isBackspace = (e.keyCode === 8);
        }

        if (!isBackspace) return;

        onChange([]);
    }

    const chosenclients = clients.filter(_client => values.indexOf(_client.id) !== -1)

    if (maxClients === 1) {
        return (
            <Group>
                <Form.Control
                    placeholder={placeholder || "Search or upload a client"}
                    value={chosenclients.length > 0 ? chosenclients[0].title : ""}
                    onKeyUp={handleKeyUp}
                    onChange={() => null}
                    required={required}
                />
                {(clients.length > 0) &&
                    <Select size="sm"
                        variant="link"
                        items={clients.filter(i => values.indexOf(i.id) === -1)}
                        maxItems={1}
                        onSelect={ids => onChange(ids)}
                    >
                        <i className="fas fa-search text-secondary" />
                    </Select>
                }


                {(allowUpload && values.length === 0) &&
                    <ClientUpload size="sm" variant="link" onUpload={client => handleClientUpload(client)}>
                        <i className="fas fa-plus-circle text-secondary" />
                    </ClientUpload>
                }

                {required &&
                    <Feedback type="invalid">
                        {errorText || "A file must be selected"}
                    </Feedback>}
            </Group>
        )
    }

    return (
        <>
            <div className="d-sm-flex justify-content-between align-items-center">
                <div>
                    {values.length > 0 ? chosenclients.map(c => (
                        <span style={itemStyle} className="text-white" key={c.id}>
                            {c.title}
                            <a href="#." className="ms-2 text-light" onClick={e => removeClient(e, c.id)}>
                                <i className="fas fa-times" />
                            </a>
                        </span>
                    )) : "No clients selected"}
                </div>
                <div>

                    {((!maxClients || (values.length < maxClients)) && ((clients.length - values.length) > 0)) &&
                        <Select size="sm"
                            variant="link"
                            items={clients.filter(i => values.indexOf(i.id) === -1)}
                            maxItems={maxClients ? (maxClients - values.length) : false}
                            onSelect={ids => onChange([...values, ...ids])}
                        >
                            <i className="fas fa-search me-1" />Search
                        </Select>
                    }

                    {((!maxClients || (values.length < maxClients)) && allowUpload) &&
                        <ClientUpload size="sm" variant="link" onUpload={file => handleClientUpload(file)}>
                            <i className="fas fa-plus-circle me-1" />Upload
                        </ClientUpload>
                    }


                </div>
            </div>

            {required &&
                <div>
                    <Form.Control value={values.join(",")} onChange={() => null} required className="d-none" />
                    <Feedback type="invalid">
                        {errorText || "At least one client must be selected."}
                    </Feedback>
                </div>
            }
        </>
    )
}

export { ClientUpload };
export default ClientInput;