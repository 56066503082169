
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import { NewInvoice } from './invoice-manipulation';
import ViewInvoices from './view-invoices';
import InvoiceDetails from './view-invoice';
import { useContext } from 'react';


const Invoices = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 3) {
        return <ErrorLoading>You do not have the permission to access payments.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/new" element={<NewInvoice />} />
            <Route path="/:invoiceid/*" element={<InvoiceDetails />} />
            <Route path="/" element={<ViewInvoices />} />
        </Routes>
    )
}

export default Invoices;