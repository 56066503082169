import { APIURL, getData, postData } from "../fetch";

/**
 * 
 * @typedef {{
 * id: string
 * item_id: string 
 * item: string|import("./inventory").InventoryItem
 * pricelist_item_id: string
 * pricelist_item: string|PricelistObject
 * quantity: number
 * date_added: string
 * added_by: string
 * updated_by: string 
 * date_updated: string
 * }} PricelistItemObject
 */


/**
 * 
 * @typedef {{
 * id: string
 * name: string 
 * description: string
 * price: number
 * no_jobs: integer
 * no_components: integer
 * inventory: PricelistItemObject[]
 * total_revenue: number
 * isActive: boolean
 * date_added: string
 * added_by: string
 * updated_by: string 
 * date_updated: string
 * }} PricelistObject
 */


/**
 * 
 * @param {PricelistObject} data 
 * @returns {Promise<{code: number, message: string, item: PricelistObject}>}
 */
const createItem = (data) => postData({ url: `${APIURL}/pricelist`, data })


/**
 * 
 * @param {PricelistObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, item: PricelistObject}>}
 */
const updateItem = (data, id) => postData({ url: `${APIURL}/pricelist/${id}`, data })

/**
 * 
 * @param {PricelistObject} data 
 * @returns {Promise<{code: number, message: string, pricelist: PricelistObject[]}>}
 */
const getItems = () => getData({ url: `${APIURL}/pricelist` })

/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, item: PricelistObject}>}
 */
const getItem = (id, includes = null) => {

    const obj = { url: `${APIURL}/pricelist/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * @returns {Promise<{code: number, message: string}>}
 */
const activateItem = (id) => postData({ url: `${APIURL}/pricelist/${id}/activate` })

/**
 * @returns {Promise<{code: number, message: string}>}
 */
const dectivateItem = (id) => postData({ url: `${APIURL}/pricelist/${id}/deactivate` })


export {
    createItem,
    getItems,
    getItem,
    updateItem,
    activateItem,
    dectivateItem
}