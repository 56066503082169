import fetchData, { APIURL, postData } from "../fetch"


/**
 * @typedef {{
 * id: string, 
 * display_name: string, 
 * username: string, 
 * other_names: string, 
 * last_name: string, 
 * email: string, 
 * telephone: string, 
 * last_logged_in: string, 
 * last_changed_password: string
 * account_id: string
 * permission_level: number
 * roles: string[]
 * }} AuthObject
 */

/**
 * 
 * @param {string} email 
 * @param {string} password 
 * @returns {Promise<{code: number, message: string, user_details: AuthObject}>}
 */
const loginUser = (email, password) => {

    return fetchData({
        url: `${APIURL}/auth/authenticate`,
        method: "POST",
        data: { email, password }
    })
}

const logoutUser = () => {
    return fetchData({ url: `${APIURL}/auth/logout`, method: "POST" })
}


/**
 * @returns {Promise<{
 *  code: number
 *  message: string,
 *  user_details: AuthObject
 * }>}
 */
const getAuthStatus = () => {
    return fetchData({
        url: `${APIURL}/auth/status`
    })
}


/**
 * 
 * @param {string} i 
 * @param {string} s 
 * @returns {Promise<{code: number, user_details: {display_name: string, user_id: string}}>}
 */
const verifyResetParameters = (i, s) => {

    return postData({
        url: `${APIURL}/auth/verify-reset-parameters`,
        data: { i, s }
    })
}


/**
 * 
 * @param {{oldPassword: string, newPassword: string, newPassword2: string}} data 
 * @returns {Promise<{code: string, message: string, user_details: AuthObject}>}
 */
const activateAccount = data => postData({ url: `${APIURL}/auth/activate`, data })



/**
 * 
 * @param {string} o 
 * @param {string} n 
 * @param {string} c 
 * @returns  {Promise<{code: string, message: string}>}
 */
const changeAccountPassword = (o, n, c) => postData({
    url: `${APIURL}/auth/change-password`,
    data: {
        old_password: o,
        password: n,
        cpassword: c
    }
})


/**
 * @returns {Promise<{
 *  code: number
 *  message: string,
 *  user_details: AuthObject
 * }>}
 */
const getUserDetails = () => fetchData({
    url: `${APIURL}/auth/user-details`
});

/**
 * 
 * @param {string} email 
 * @returns {Promise<{code: number, message: string}>}
 */
const forgotPassword = (email) => {
    return fetchData({
        url: `${APIURL}/auth/recover`,
        method: "POST",
        data: { email }
    })
}

export {
    getAuthStatus,
    loginUser,
    logoutUser,
    verifyResetParameters,
    activateAccount,
    changeAccountPassword,
    getUserDetails,
    forgotPassword
}