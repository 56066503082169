import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from '../../components/loading';
import PageHeader, { Title } from '../../components/pageheader';
import EventForm from './job-form';
import { useContext } from 'react';
import { AppUserContext } from '../../App';

const eventObject = {
    category: "", client: "", client_contact_id: "", location: "", job_date: "", end_date: "",
    title: "", deliverables: ""
}

const NewJob = () => {

    const nav_items = [
        { title: 'Jobs', href: '/app/jobs' },
        { title: 'New Job' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        navigate(`/app/jobs/${details.id}`);
    }

    const [searchParams] = useSearchParams();
    const [details, setDetails] = useState(eventObject);
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        let job_date = searchParams.get('date') || "",
            client_id = searchParams.get('client') || "";

        const _details = { ...details, job_date, end_date: job_date, client_id };

        setDetails(_details);
        setLoaded(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!loaded) return <PageLoading />


    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <EventForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/jobs").JobObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import("../../resources/api/jobs").JobObject>>} props.setDetails 
 */
const EditJob = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Jobs', href: '/app/jobs' },
        { title: details.job_no, href: `/app/jobs/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();


    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/jobs/${details.id}`);
    }


    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 3) {
        return <ErrorLoading>You do not have the permission to edit jobs.</ErrorLoading>
    }

    if ((profile.id !== details.lead_contact_id) && (profile.permission_level !== 1))
        return <ErrorLoading>Only the person who input the job can edit it.</ErrorLoading>


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>{details.job_no}</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <EventForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewJob, EditJob };