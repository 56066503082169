import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';


const Title = ({ title, children }) => <h4 className="page-header-title">{title || children}</h4>;

const PageHeader = ({ maxWidth, children }) => {
    return <div className={`page-header mb-5 max-${maxWidth}`}>{children}</div>;
}

const ActionBar = ({ children, size = "sm" }) => {
    const cls = size === 'sm' ? 'bar-sm' : '';

    return <div className={`page-header-actionbar ${cls}`}>{children}</div>;
}

const ButtonToolbar = ({ children }) => {
    return <div className="btn-group">{children}</div>
}

const ActionButton = ({ href = null, onClick = null, children, className, isExternalLink = false, ...props }) => {
    if (href) {
        if (isExternalLink) {
            return <a target="_blank" rel="noreferrer" href={href} className="btn border-0" {...props}
                onMouseEnter={e => e.currentTarget.classList.add('hover')}
                onMouseLeave={e => e.currentTarget.classList.remove('hover')}
            >{children}{props.title && <span className="show-on-hover ms-2">{props.title}</span>}
            </a>
        } else {
            return <Link to={href} className="btn border-0" {...props}
                onMouseEnter={e => e.currentTarget.classList.add('hover')}
                onMouseLeave={e => e.currentTarget.classList.remove('hover')}
            >{children}{props.title && <span className="show-on-hover ms-2">{props.title}</span>}
            </Link>
        }

    } else {
        return <Button onClick={onClick} {...props} className="border-0"
            onMouseEnter={e => e.currentTarget.classList.add('hover')}
            onMouseLeave={e => e.currentTarget.classList.remove('hover')}
        >
            {children}{props.title && <span className="show-on-hover ms-2">{props.title}</span>}
        </Button>
    }
}

export { ActionBar, ActionButton, Title, ButtonToolbar };
export default PageHeader;