import moment from "moment";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import { CancelButton, SubmitButton } from "../../components/btns";
import { ErrorLoading, PageLoading } from "../../components/loading";
// import { UCWords } from "../../components/resources";
import { errorAlert, successAlert } from "../../components/toastr";
import { changeAccountPassword, getUserDetails } from "../../resources/api/auth";

const { Group, Label, Control } = Form;

/**
 * Component for changing profle details
 * @param {Object} props
 * @param {boolean} props.changePassword
 * @returns 
 */

const ProfileDetails = ({ changePassword = false }) => {

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    const [details, setDetails] = useState({})

    useEffect(() => {
        getUserDetails()
            .then(({ user_details }) => {
                setDetails(user_details);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));
    }, [])


    const nav_items = [
        { title: details.display_name }
    ]

    const imgsrc =
        `https://eu.ui-avatars.com/api/?name=${details.other_names}+${details.last_name}&size=128`



    if (!loaded) return <PageLoading />

    if (error) return <ErrorLoading>{error}</ErrorLoading>


    return (
        <>
            <Nav items={nav_items} />

            <div className="max-700">

                <Row className="py-2 mb-4">
                    <Col sm={9}>
                        <img src={imgsrc} alt="..." width="100" className="rounded-circle img-thumbnail shadow-sm" />
                    </Col>
                </Row>

                <Row className="py-2 border-bottom">
                    <Col sm={3} className="small text-muted text-uppercase">
                        NAME
                    </Col>
                    <Col sm={9}>
                        {`${details.other_names} ${details.last_name}`}
                    </Col>
                </Row>


                <Row className="py-2 border-bottom">
                    <Col sm={3} className="small text-muted text-uppercase">
                        EMAIL
                    </Col>
                    <Col sm={9}>
                        {details.email}
                    </Col>
                </Row>

                <Row className="py-2 border-bottom">
                    <Col sm={3} className="small text-muted text-uppercase">
                        telephone
                    </Col>
                    <Col sm={9}>
                        {details.telephone}
                    </Col>
                </Row>

                <Row className="py-2 border-bottom">
                    <Col sm={3} className="small text-muted text-uppercase">
                        Job title
                    </Col>
                    <Col sm={9}>
                        {details.job_title}
                    </Col>
                </Row>

                <Row className="py-2 border-bottom">
                    <Col sm={3} className="small text-muted text-uppercase">
                        Permission Level
                    </Col>
                    <Col sm={9}>
                        {details.permission_level}
                    </Col>
                </Row>

                <Row className="py-2 border-bottom">
                    <Col sm={3} className="small text-muted text-uppercase">
                        Last Logged In
                    </Col>
                    <Col sm={9}>
                        {moment(details.last_logged_in).format("ddd DD MMMM YYYY, HH:mm")}
                    </Col>
                </Row>

                <Row className="py-2 border-bottom">
                    <Col sm={3} className="small text-muted text-uppercase">
                        Last changed password
                    </Col>
                    <Col sm={9} className="d-flex justify-content-between">
                        {moment(details.last_changed_password).format("ddd DD MMMM YYYY, HH:mm")}
                        <Link to="/app/me/change-password" className="small">Change Password</Link>
                    </Col>
                </Row>
            </div>

            <ChangePassword show={changePassword} />

        </>
    )
}


/**
 * 
 * Form for changing password
 * @param {Object} props 
 * @returns 
 */
const ChangePassword = ({ show = false }) => {

    const [validated, setValidated] = useState(false);
    const [handlingSubmit, setSubmitting] = useState(false);
    const ref = useRef();

    const navigate = useNavigate();

    const [passwords, setPasswords] = useState({ o: "", n: "", cn: "" })

    const handleSubmit = () => {

        if (!ref.current.checkValidity()) {
            setValidated(true);
            errorAlert("Ensure the form is filled in corrrectly");
            return false;
        }

        setValidated(false);
        setSubmitting(true);

        const { o, n, cn } = passwords;

        changeAccountPassword(o, n, cn)
            .then(({ message }) => {
                successAlert(message);
                navigate(-1);
            })
            .catch(e => {
                errorAlert(e);
            })
            .finally(() => setSubmitting(false))
    }

    const handleExit = () => {
        setValidated(false);
        setPasswords({ o: "", n: "", cn: "" });
    }

    return (
        <Modal show={show} size="sm" onExit={handleExit} centered>
            <Modal.Body>
                <Form noValidate validated={validated} ref={ref}>

                    <Row className="g-1">
                        <Col sm={12} className="my-1">
                            <Group controlId="currentPassword">
                                <Label className="form-field-title">Current Password</Label>
                                <Control
                                    type="password"
                                    required
                                    autoComplete="current-password"
                                    value={passwords.o}
                                    onChange={e => setPasswords({ ...passwords, o: e.currentTarget.value })}
                                />

                                <Control.Feedback type="invalid">
                                    Old password is required.
                                </Control.Feedback>
                            </Group>
                        </Col>
                        <Col sm={12} className="my-1">
                            <Group controlId="newPassword">
                                <Label className="form-field-title">New Password</Label>
                                <Control
                                    type="password"
                                    required
                                    autoComplete="new-password"
                                    value={passwords.n}
                                    onChange={e => setPasswords({ ...passwords, n: e.currentTarget.value })}
                                />

                                <Control.Feedback type="invalid">
                                    New password is required.
                                </Control.Feedback>
                            </Group>
                        </Col>
                        <Col sm={12} className="my-1">
                            <Group controlId="confNewPassword">
                                <Label className="form-field-title">Confirm Password</Label>
                                <Control
                                    type="password"
                                    required
                                    autoComplete="new-password"
                                    value={passwords.cn}
                                    onChange={e => setPasswords({ ...passwords, cn: e.currentTarget.value })}
                                />

                                <Control.Feedback type="invalid">
                                    Confirmation password is required.
                                </Control.Feedback>
                            </Group>
                        </Col>
                    </Row>

                    <Row className="mt-3 text-end">
                        <Col>
                            <SubmitButton
                                onClick={handleSubmit}
                                isSubmitting={handlingSubmit}
                            >
                                Submit
                            </SubmitButton>
                            <CancelButton
                                isSubmitting={handlingSubmit}
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>


            </Modal.Body>
        </Modal>
    )
}


export default ProfileDetails;