export const unconfirmedJobStatuses = [
    "open",
    'processing',
    "awaiting-client",
    "awaiting-deposit",
    "awaiting-confirmation"
]

export const uncompletedJobStatuses = [
    "confirmed",
    "processed",
    "delievered"
]

export const completedJobStatuses = [
    "completed",
    "closed",
]


export const confirmedJobStatuses = [
    ...uncompletedJobStatuses,
    ...completedJobStatuses
]

export const cancelledJobStatuses = [
    'cancelled',
]


export const JobStatuses = [
    ...unconfirmedJobStatuses,
    ...confirmedJobStatuses,
    ...cancelledJobStatuses
]

export const EventCategories = [
    'conference',
    'seminar',
    'corporate',
    'workshop',
    'graduation',
    "government",
    'wedding',
    'party',
    'introduction',
    'funeral',
    'other'
].sort()

export const PricelistCategories = [
    "tents",
    "tables",
    "chairs",
    "mobile-toilets",
    "stages",
    "carpets"
]


export const EventTypes = [
    "event-equipment-hire",
    "tents",
    "events-management",
    "other"
]

export const InventoryCategories = [
    "projection",
    "sound",
    "lighting",
    "stage",
    "tents"
]

export const InventoryUnits = [
    "pieces",
    "boxes",
    "cans", 
    "metres"
]

export const AdjustmentTypes = [
    "retirement",
    "loss"
]


export const EventColours = [
    "green",
    "yellow",
    "orange",
    "teal",
    "purple",
    "dark-blue",
    "sky-blue",
    "gray",
    "red",
    "pink",
    "indigo"
]

export const EventColourMaping = {
    "green": ['bg-success text-white border-success'],
    "yellow": ['bg-warning text-white border-warning'],
    "orange": ['bg-orange text-white border-orange'],
    "teal": ['bg-teal text-white border-teal'],
    "purple": ['bg-purple text-white border-purple'],
    "dark-blue": ['bg-primary text-white border-primary'],
    "sky-blue": ['bg-info text-white border-info'],
    "gray": ['bg-secondary text-white border-secondary'],
    "red": ['bg-danger text-white border-danger'],
    "pink": ['bg-pink text-white border-pink'],
    "indigo": ['bg-indigo text-white border-indigo']
}

export const expenseCategories = [
    "airtime-and-commuunication",
    "bank-charges",
    "capital-investments",
    "cleaning",
    "commissions",
    "consultations",
    "detergents",
    "donations",
    "education-and-training",
    "electricity",
    "facilitation",
    "food",
    "fuel",
    "gifts-and-donations",
    "insurance",
    "internet",
    "IT-services",
    "medical",
    "miscellaneous",
    "non-business",
    "office",
    "other",
    "printing",
    "product-hire",
    "promotions",
    "refund",
    "renovations",
    "rent",
    "repairs",
    "stationary",
    "statutory-dues",
    "taxes",
    "tent-repairs",
    "toilet-cleaning",
    "training-and-seminars",
    "transport-facilitation",
    "utilities",
    "vehicle-rentals",
    "vehicle-repairs",
    "vehicle-service",
    "water"
]

export const nonChoiceExpenseCategories = [
    "allowances",
    "advances",
    "wages-salaries",
]

export const allExpenseCategories = [
    ...expenseCategories,
    ...nonChoiceExpenseCategories
];

export const expenseStatuses = [
    "non-billable",
    "unbilled",
    "invoiced"
];

export const paymentMethods = [
    "bank-transfer",
    "cash",
    "cheque",
    "credit-card",
    "direct-debit",
    "mobile-payment"
]

export const taxes = [
    { title: "VAT", value: 18 }
]

export const financialAccounts = [
    "DFCU",
    "BoA",
    "stanbic",
    "centenary",
    "petty-cash",
    "undeposited-funds"
]

export const AccountTypes = [
    "bank",
    "mobile-money",
    "cash"
]

export const assortedPaymentMethods = {
    "bank-transfer": ["bank", "mobile-money"],
    "cash": ["cash"],
    "cheque": ["bank"],
    "credit-card": ["bank"],
    "direct-debit": ["bank"],
    "mobile-payment": ["bank", "mobile-money"]
}


export const DefaultTerms = [
    "Accounts are due on demand.",
    "All payments made to Tents 4 U Limited.",
    "Payment should made before event day.",
    "This quotation is not inclusive of any taxes.",
    "Event service delivery only after issuance of an LPO from the client.",
    "60% advance payment; Balance must be paid within 7 days after tax invoicing.",
]

export const DefaultNotes = [
    "Payments to be made to: \nAccount Name: Karid Investments Ltd T/A Tents 4 U. \nBank: Bank of Africa Uganda Limited, Luzira Branch. \nAccount No: 10207280003"
]

export const jobDocumentCategories = [
    'confirmation',
    'lpo',
    'effris',
    'invoice',
    'other'
];

export const fileIcons = {
    pdfs: "far fa-file-pdf",
    images: "fas fa-file-image",
    sheets: "fas fa-file-excel",
    docs: "fas fa-file-word",
    slides: "fas fa-file-powerpoint",
    videos: "fas fa-file-video",
    audios: "fas fa-file-audio",
}

export const staffTypes = [
    "casual",
    "permanent",
    "consultant"
]

export const allowanceCategories = [
    "overtime",
    "operational",
    "weekend"
]

export const payrollTypes = {
    wages: {
        isManual: true,
        requiresDuration: false,
        definedRates: true,
        title: "Wages",
        doOvertime: false,
        expenseCategory: "wages-salaries"
    },
    salary: {
        isManual: true,
        requiresDuration: false,
        definedRates: true,
        title: "Monthly Salaries",
        doOvertime: false,
        expenseCategory: "wages-salaries"
    },
    allowance: {
        isManual: true,
        requiresDuration: false,
        definedRates: false,
        title: "Allowances",
        doOvertime: false,
        expenseCategory: "allowances"
    },
    advance: {
        isManual: true,
        requiresDuration: true,
        definedRates: false,
        title: "Advances",
        doOvertime: false,
        expenseCategory: "advances"
    },
}


export const payrollPaymentStatus = [
    "open", "paritally-processed", "processed", "paid"
]

export { PricelistCategories as JobDeliverables }