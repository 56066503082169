import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Routes, Route, useParams, Link, useNavigate } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import cur from "../../components/currency";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { UCWords } from "../../components/resources";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import { deletePayment, getPayment } from "../../resources/api/payments";
import { EditPayment } from "./payment-manipulation";
import { APIURL } from "../../resources/fetch";
import { AppUserContext } from "../../App";
import { ConfirmAction } from "../../components/prompt";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/payments").PaymentObject} props.details
 */
const ViewDetails = ({ details }) => {

    const nav_items = [
        { title: 'Payments', href: "/app/payments" },
        { title: details.receipt_no }
    ]

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };

    const navigate = useNavigate();

    const { profile } = useContext(AppUserContext);

    const handleDelete = () => {
        infoAlert("Deleting Payment, please wait...");

        deletePayment(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate("/app/payments");
            })
            .catch(e => errorAlert(e));
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>{details.receipt_no}</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn title="Download Receipt" href={`${APIURL}/payments/${details.id}/pdf`} isExternalLink>
                            <i className="fas fa-file-pdf" />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        {(
                            (profile.permission_level === 1)
                        ) &&
                            <ConfirmAction
                                as={Btn}
                                title="Delete Payment"
                                confirmationText="Are you sure you want to delete this payment?"
                                handleAction={handleDelete}
                            >
                                <i className="fas fa-trash-alt" />
                            </ConfirmAction>
                        }
                        <Btn href={`/app/payments/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                    </ButtonToolbar>

                    <ButtonToolbar>
                        <Btn href="/app/payments/new" title="New Payment">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-800">


                <div className="text-end">
                    REFERENCE: <span style={style1}>{details.reference}</span> <br />
                    PAYMENT DATE: <span style={style1}>{moment(details.payment_date).format("DD MMMM YYYY")}</span> <br />
                    METHOD: <span style={style1}>{UCWords(details.method.replace(/-/g, " "))}</span> <br />
                    ACCOUNT: <span style={style1}>{details.account.title}</span><br />
                    PAID BY: <span style={style1}>{details.payee}</span><br />
                </div>
                <h4>Client Details</h4>
                <Link to={`/app/clients/${details.client_id}`}>{details.client.title}</Link> <br />
                {details.client.email}<br />
                {details.client.telephone}


                <h4 className="mt-2 mt-sm-4">Remarks</h4>
                {details.remarks}

                <h4 className="mt-2 mt-md-4">Invoices</h4>
                <Table responsive hover striped size="sm" style={{ minWidth: "500px", maxWidth: "700px" }}>
                    <thead>
                        <tr>
                            <th>Invoice</th>
                            <th className="text-end">Total Amount</th>
                            <th className="text-end">Paid Amount</th>
                            <th className="text-end">Current Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            details.items.map(i => (
                                <tr key={i.id}>
                                    <td><Link to={`/app/invoices/${i.invoice_id}`}>{i.invoice_no}</Link></td>
                                    <td className="text-end">{cur(i.total_amount, 0).format()}</td>
                                    <td className="text-end">{cur(i.amount, 0).format()}</td>
                                    <td className="text-end">{cur(i.balance, 0).format()}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>


                <p className="mt-5">
                    Paid via {details.method} {details.paid_to && ` to ${details.paid_to}`}
                </p>

            </div>

        </>
    )

}


const PaymentDetails = () => {

    const { paymentid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setLoaded(false);
        setError();

        getPayment(paymentid, ['items', 'account', 'client'])
            .then(({ payment }) => setDetails(payment))
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [paymentid])

    if (!isLoaded) return <PageLoading>Loading payment details...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>

    return (
        <Routes>
            <Route path="/edit" element={<EditPayment details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewDetails details={details} />} />
        </Routes>
    )

}


export default PaymentDetails;