
import React, { useEffect, useState } from "react";
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { getStorePackingList, signoffReturnStorePackingList, signoffStorePackingList } from "../../resources/api/jobs";
import { Link, Route, Routes } from "react-router-dom";
import { Table } from "react-bootstrap";
import { UCWords } from "../../components/resources";
import moment from "moment";
import EditPackingList from "./packing-list-edit";
import { ConfirmAction } from "../../components/prompt";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import ReturnPackingList from "./packing-list-return";
import { APIURL } from "../../resources/fetch";



/**
 * @param {Object} props
 * @param {import("../../resources/api/jobs").JobObject} props.details
 * @param {import("../../resources/api/jobs").PackingListObject[]} props.items
 */
const ListDetails = ({ details, items, updateJob, setItems }) => {

    const nav_items = [
        { title: 'Jobs', href: "/app/jobs" },
        { title: details.job_no, href: `/app/jobs/${details.id}` },
        { title: "Packing List" }
    ]

    const handleSignOff = () => {
        infoAlert("Signing off list...");

        signoffStorePackingList(details.id)
            .then(({ message, job }) => {
                updateJob(job);
                successAlert(message);
            })
            .catch(errorAlert)
    }

    const handleReturn = () => {
        infoAlert("Signing off list...");

        signoffReturnStorePackingList(details.id)
            .then(({ message, job, items }) => {
                updateJob(job);
                setItems(items);
                successAlert(message);
            })
            .catch(errorAlert)
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.title} - Store Packing List</Title>
                <ActionBar>

                    <ButtonToolbar>
                        <Btn href={`${APIURL}/jobs/${details.id}/packing-list/pdf`} title="Print Packing List" isExternalLink>
                            <i className='fas fa-file-pdf' />
                        </Btn>
                    </ButtonToolbar>

                    {(details.packing_list_status === "draft") &&
                        <ButtonToolbar>
                            <Btn href={`/app/jobs/${details.id}/packing-list/edit`} title="Edit Packing List">
                                <i className="fas fa-pencil-alt" />
                            </Btn>
                            <ConfirmAction
                                confirmationText="Are you sure you want to sign off this list?"
                                title="Sign Off List"
                                as={Btn}
                                handleAction={handleSignOff}
                            >
                                <i className="fas fa-check-circle" />
                            </ConfirmAction>
                        </ButtonToolbar>
                    }

                    {(details.packing_list_status === "signed_off") &&
                        <ButtonToolbar>
                            <Btn href={`/app/jobs/${details.id}/packing-list/return`} title="Return Packing List">
                                <i className="fas fa-rotate-left" />
                            </Btn>
                            <ConfirmAction
                                confirmationText="Are you sure you want to mark this list as returned?"
                                title="Mark As Returned"
                                as={Btn}
                                handleAction={handleReturn}
                            >
                                <i className="fas fa-check-circle" />
                            </ConfirmAction>
                        </ButtonToolbar>
                    }

                    <ButtonToolbar>
                        <Btn href="/app/jobs/new" title="New Job">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">

                <dl>
                    <dt>Status</dt>
                    <dd>{UCWords(details.packing_list_status.replace(/_/g, " "))}</dd>
                    <dt>Created</dt>
                    <dd>{moment(details.packing_list_created_date).format("ddd DD MMMM Y")}</dd>
                    {(!!details.packing_list_signoff_date) && <>
                        <dt>Signed Off</dt>
                        <dd>{moment(details.packing_list_signoff_date).format("ddd DD MMMM Y")}</dd>
                    </>}
                    {(!!details.packing_list_return_date) && <>
                        <dt>Returned</dt>
                        <dd>{moment(details.packing_list_return_date).format("ddd DD MMMM Y")}</dd>
                    </>}
                </dl>
                <Table responsive style={{ minWidth: '600px' }} hover className="items-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Item</th>
                            <th>Quantity</th>
                            {(["returned", "signed_off"].indexOf(details.packing_list_status) !== -1) &&
                                <th>Returned</th>
                            }
                            <th>Unit</th>
                            <th colSpan={2}>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items.map((e, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                        <Link to={`/app/inventory/${e.item_id}`} className="d-block font-weight-normal">
                                            {e.name}
                                        </Link>
                                    </td>
                                    <td>{e.quantity}</td>
                                    {(["returned", "signed_off"].indexOf(details.packing_list_status) !== -1) &&
                                        <td>{e.returned} {e.adjustment_id && <Link to={`/app/inventory/${e.item_id}`}>Loss</Link>}</td>
                                    }
                                    <td>{e.unit}</td>
                                    <td className="small">{e.comments || 'n/a'}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>


            </div>



        </>
    )

}



/**
 *
 * @param {Object} props
                * @param {import("../../resources/api/jobs").JobObject} props.details
                * @param {(job: import("../../resources/api/jobs").JobObject) => void} props.updateJob
                */
const PackingList = ({ details, updateJob }) => {

    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();
    const [items, setItems] = useState([]);

    useEffect(() => {

        setLoaded(false);
        setError(null);

        getStorePackingList(details.id)
            .then(({ items }) => {
                // job.notes.sort((a, b) => sortFunction(a, b, 'date_added'));
                setItems(items);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [details.id])

    //load events details
    if (!isLoaded) {
        return <PageLoading>Loading job details...</PageLoading>
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>

    return (
        <Routes>
            <Route path="/return" element={<ReturnPackingList details={details} items={items} setItems={setItems} />} />
            <Route path="/edit" element={<EditPackingList details={details} items={items} setItems={setItems} />} />
            <Route path="/" element={<ListDetails details={details} items={items} updateJob={updateJob} setItems={setItems} />} />
        </Routes>
    )

}





export default PackingList;