import React, { useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../../components/btns";
import { errorAlert, successAlert } from "../../../components/toastr";
import { Select } from "../../../components/select";
import { createPackingListItem, updatePackingListItem } from "../../../resources/api/jobs";


const { Control, Label } = Form;

/**
 * @param {Object} props
 * @param {"create"|"user"|"salary"} props.action
 * @param {(details: import("../../../resources/api/jobs").DeliveryListObject) => void} props.onUpload
 * @param {{
 *  show: boolean
 *  setShow: React.Dispatch<React.SetStateAction<boolean>>
 * }} props.show
 * @param {{
 *  details: import("../../../resources/api/jobs").DeliveryListObject
 *  setDetails: React.Dispatch<React.SetStateAction<import("../../../resources/api/jobs").DeliveryListObject>>
 * }} props.itemDetails
 */
const ItemForm = ({ onUpload, priceListItems = [], action = "create", itemDetails, show: toShow, jobId }) => {

    const inputref = useRef();

    const { show, setShow } = toShow;
    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [validated, setValidated] = useState(false);

    const { details, setDetails } = itemDetails;


    const onPricelistItemchange = ids => {

        const [id] = ids;

        if (!id) {
            setDetails(d => ({
                ...d,
                pricelist_item_id: ""
            }))

            return;
        }

        let _item = priceListItems.find(it => it.id === id);

        setDetails(d => ({
            ...d,
            pricelist_item_id: _item.id,
            title: _item.name
        }))

    }



    /**
     * Handles the submission of the form
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = inputref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("Some errors have been detected in the form. These have been highlighted for you.", "Invalid Fields");
            return;
        }

        setHandlingSubmit(true);
        setValidated(false);

        let promise;

        if (action === "create") {
            promise = createPackingListItem({ ...details, job_id: jobId });
        } else {
            promise = updatePackingListItem(details, details.id);
        }

        promise
            .then(({ item, message }) => {
                form.reset();
                successAlert(message);
                onUpload(item);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => setHandlingSubmit(false))
    }

    return (
        <Modal show={show} onExit={() => setValidated(false)} centered animation={false}>
            <Modal.Body>
                <Form noValidate validated={validated} ref={inputref}>

                    <Row className="g-1">

                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">Title</Label>
                            <InputGroup>

                                <Control
                                    value={details.title}
                                    onChange={e => setDetails({ ...details, title: e.currentTarget.value })}
                                    required
                                    readOnly={!!details.pricelist_item_id}
                                />
                                <Select
                                    onSelect={onPricelistItemchange}
                                    maxItems={1}
                                    variant="outline-secondary"
                                    items={priceListItems}
                                    className="border-0"
                                >
                                    <i className="fas fa-search" />
                                </Select>
                                <Control.Feedback type="invalid">
                                    Item must have a title
                                </Control.Feedback>
                            </InputGroup>
                            {!!details.pricelist_item_id &&
                                <Button
                                    size="sm"
                                    variant="link"
                                    className="text-danger"
                                    onClick={() => setDetails(d => ({ ...d, pricelist_item_id: "", title: "" }))}
                                >
                                    <i className="fas fa-times me-1" />Remove price-list item
                                </Button>
                            }
                        </Col>
                    </Row>
                    <Row className="g-1">
                        <Col sm={6} className="my-1">
                            <Label className="form-field-title">Quantity</Label>
                            <Control
                                type="number"
                                value={details.quantity}
                                onChange={e => setDetails({ ...details, quantity: e.currentTarget.value })}
                                required
                            />
                            <Control.Feedback type="invalid">
                                Required field
                            </Control.Feedback>
                        </Col>
                        <Col sm={6} className="my-1">
                            <Label className="form-field-title">No Days</Label>
                            <Control
                                type="number"
                                value={details.days}
                                onChange={e => setDetails({ ...details, days: e.currentTarget.value })}
                                required
                            />
                            <Control.Feedback type="invalid">
                                Required field
                            </Control.Feedback>
                        </Col>
                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">Comments</Label>
                            <Control
                                as="textarea"
                                value={details.comments}
                                onChange={e => setDetails({ ...details, comments: e.currentTarget.value })}

                            />
                        </Col>
                    </Row>


                    <Row>
                        <Col className="mt-4 text-end">
                            <SubmitButton isSubmitting={handlingSubmit} onClick={handleSubmit}>
                                {action === "edit" ? "Update" : "Submit"}
                            </SubmitButton>
                            <CancelButton isSubmitting={handlingSubmit} onClick={() => setShow(false)}>
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}


export default ItemForm;