import { APIURL, postData, deleleData, getData } from "../fetch";

/**
 * 
 * @typedef {{
 * payments: import("./payments").PaymentObject[]
 * invoices: import("./invoices").InvoiceObject[]
 * }} StatementObject 
 */


/**
 * 
 * @typedef {{
 * id: string,
 * code: string
 * title: string,
 * address: string
 * no_jobs: number 
 * total_revenue: number 
 * date_added: string
 * added_by: string
 * jobs: import("./jobs").JobObject
 * invoices: import("./invoices").InvoiceObject
 * contact_persons: ContactPersonObject[]
 * }} ClientObject 
 */


/**
 * 
 * @typedef {{
 *  id: string 
 *  client_id: string
 *  contact_id: string
 *  display_name: string 
 *  other_names: string 
 *  last_name: string 
 *  email: string
 *  telephone: string
 * }} ContactPersonObject
 */



/**
 * 
 * @param {ClientObject} data 
 * @returns {Promise<{code: number, message: string, client: ClientObject}>}
 */
const createClient = (data) => {
    return postData({
        url: `${APIURL}/clients`,
        data
    })
}

/**
 * 
 * @param {ClientObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, client: ClientObject}>}
 */
const updateClient = (data, id) => {
    return postData({
        url: `${APIURL}/clients/${id}`,
        data
    })
}

/**
 * 
 * @param {ClientObject} data 
 * @returns {Promise<{code: number, message: string, clients: ClientObject[]}>}
 */
const getClients = () => getData({ url: `${APIURL}/clients` })


/**
 * 
 * @param {ClientObject} data 
 * @returns {Promise<{code: number, message: string, clients: ClientObject[]}>}
 */
const getDebtors = () => getData({ url: `${APIURL}/clients/debtors` })

/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, client: ClientObject}>}
 */
const getClient = (id, includes = null) => {

    const obj = { url: `${APIURL}/clients/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {ContactPersonObject} data 
 * @returns {Promise<{code: number, message: string, contact_person: ContactPersonObject}>}
 */
const createClientContact = (data) => {
    return postData({
        url: `${APIURL}/contact-persons`,
        data
    })
}

const getClientContacts = id => getData({ url: `${APIURL}/contact-persons?client_id=${id}` })

/**
 * 
 * @param {string} id 
 * @returns {Promise<{code: number, message: string, statement: StatementObject}>}
 */
const getClientStatement = id => getData({ url: `${APIURL}/clients/${id}/statement` })
/**
 * 
 * @param {string} id 
 * @returns {Promise<{code: number, message: string, items: {}[]}}>}
 */
const generateStatement = id => getData({ url: `${APIURL}/clients/${id}/generate-statement` })

/**
 * 
 * @param {ContactPersonObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, contact_person: ContactPersonObject}>}
 */
const updateClientContact = (data, id) => {
    return postData({
        url: `${APIURL}/contact-persons/${id}`,
        data
    })
}

/**
 * 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, contact_person: ContactPersonObject}>}
 */
const deleteClientContact = id => deleleData({ url: `${APIURL}/contact-persons/${id}` })


const printStatement = (data) => postData({
    url: `${APIURL}/clients/statement`,
    data
});




export {
    createClient,
    getClient,
    getClients,
    updateClient,
    createClientContact,
    updateClientContact,
    deleteClientContact,
    getClientContacts,
    getClientStatement,
    generateStatement,
    printStatement,
    getDebtors
}