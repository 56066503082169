import { Routes, Route } from "react-router-dom"
import ProfileDetails from "./profile-details";

const Profile = () => {

    return (
        <Routes>
            <Route path="/change-password" element={<ProfileDetails changePassword={true} />} />
            <Route path="/" element={<ProfileDetails />} />
        </Routes>
    )
}

export default Profile;