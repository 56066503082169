import { Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { useEffect, useMemo, useState } from 'react';
import { error as errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { SubmitButton, CancelButton } from '../../components/btns';
import { DateSelector } from '../../components/datetimepicker';
import moment from 'moment';
import SelectInput, { Select } from '../../components/select';
import cur from '../../components/currency';
import {  useNavigate } from 'react-router-dom';
import { taxes } from '../../assets/constants';
import InvoiceItems, { InvoiceNotes, NewInvoiceItem } from '../../components/finances';
import { getJobs } from '../../resources/api/jobs';
import { getItems } from '../../resources/api/pricelist';
import { createInvoice, updateInvoice } from '../../resources/api/invoices';
import { useContext } from 'react';
import { AppUserContext } from '../../App';
import ClientInput from '../clients/client-selector';

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating payment
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('../../resources/api/invoices').InvoiceObject} props.details
 * @param {(details: import('../../resources/api/payments').PaymentObject) => void} props.onSubmit
 */
const InvoiceForm = ({ details: invoiceObject, action, onSubmit }) => {

    const { profile } = useContext(AppUserContext);

    const [details, setDetails] = useState(invoiceObject);
    const [items, setItems] = useState(invoiceObject.items);
    const [jobs, setJobs] = useState([]);
    const [pricelist_items, setPricelistItems] = useState([]);
    const [deleted_items, setDeletedItems] = useState([]);

    const [notes, setNotes] = useState(invoiceObject.notes || []);
    const [tcs, setTCs] = useState(invoiceObject.tcs || []);

    const [currencyChanged, setCurrencyChange] = useState(false);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    const jobDetails = useMemo(() => {

        if (!details.job_id) return null;
        return jobs.find(j => j.id === details.job_id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details.job_id, JSON.stringify(jobs)])


    const handleCurrencyChange = () => {
        setCurrencyChange(false);

        let _items = [...items].map(item => {


            let _unit_price = Math.round(item.unit_price * 100 / details.exchange_rate) / 100;


            return {
                ...item,
                hasChanged: true,
                unit_price: _unit_price,
                total_amount: (_unit_price * parseInt(item.quantity) * parseInt(item.days)) || 0
            }
        })

        setItems(_items);
    }




    useEffect(() => {
        setCurrencyChange(true);
    }, [details.currency])

    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        let promise;

        if (action === "create") {
            promise = createInvoice({
                ...details,
                currency: details.currency?.toUpperCase(),
                isTaxInclusive: details.isTaxInclusive ? 1 : 0,
                items: items.map(i => ({ ...i, isHeading: i.isHeading ? 1 : 0 })),
                notes,
                tcs
            });
        } else {
            promise = updateInvoice({
                ...details,
                currency: details.currency?.toUpperCase(),
                isTaxInclusive: details.isTaxInclusive ? 1 : 0,
                new_items: items.filter(i => i.isNew).map(i => ({ ...i, isHeading: i.isHeading ? 1 : 0 })),
                updated_items: items.filter(i => (!!i.id && i.hasChanged)).map(i => ({ ...i, isHeading: i.isHeading ? 1 : 0 })),
                deleted_items,
                notes,
                tcs
            }, details.id);
        }

        promise
            .then(({ invoice, message }) => {
                successAlert(message);
                onSubmit(invoice);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    //get clients
    useEffect(() => {

        getItems()
            .then(({ pricelist }) => {
                setPricelistItems(
                    pricelist
                        .map(e => ({
                            ...e,
                            title: `${e.name}`,
                            description: ` ${cur(e.price, 0).format()} per unit`
                        })));
            })
            .catch(e => errorAlert(e));

    }, [])


    useEffect(() => {

        if (!details.client_id) {
            setJobs([]);
            return;
        }

        infoAlert("Fetching uninvoiced jobs for client...");



        getJobs(false, { client_id: details.client_id, hasInvoice: (action === "edit") })
            .then(({ jobs }) => {
                setJobs(
                    jobs
                        .map(e => ({
                            ...e,
                            __title: e.title,
                            title: `${e.job_no} (${e.reference})`,
                            description: `${moment(e.job_date).format("DD MMM YYYY")} | ${e.location}`
                        }))
                );

                if (jobs.length > 0) {
                    successAlert("Loaded client's jobs");
                } else {
                    errorAlert("Client has no uninvoiced jobs");
                }


            })
            .catch(e => errorAlert(e));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details.client_id, action])

    const onPickItems = item_ids => {

        let current_no = items.length;

        const new_items = pricelist_items
            .filter(i => item_ids.indexOf(i.id) !== -1)
            .map(i => ({
                ...NewInvoiceItem,
                pricelist_item_id: i.id,
                isFromPriceList: 1,
                isNew: true,
                title: i.name,
                unit_price: i.price,
                description: i.description,
                position: ++current_no
            }))

        setItems(i => [...i, ...new_items]);
    }


    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Client</Label>
                    <ClientInput
                        onChange={([id]) => setDetails(d => ({
                            ...d,
                            client_id: id || "",
                            job_id: ""
                        }))}
                        value={[details.client_id]}
                        downloadClients={true}
                        placeholder='-'
                        errorText="This field is required"
                        required
                        maxClients={1}
                    />
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Job</Label>
                    <SelectInput
                        value={[details.job_id]}
                        onChange={([id]) => setDetails(d => ({
                            ...d,
                            job_id: id || ""
                        }))}
                        maxItems={1}
                        options={jobs}
                        errorText="This invoice must be attached to a job."
                        placeholder='-'
                    />
                    {!!jobDetails &&
                        <Form.Text>
                            Title: {jobDetails.__title} <br />
                            Dates: {moment(jobDetails.job_date).format("D MMM YY")} - {moment(jobDetails.end_date).format("D MMM YY")} <br />
                            Location: {jobDetails.location}
                        </Form.Text>
                    }
                </Col>
            </Row>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Invoice Date</Label>
                    <DateSelector
                        value={details.invoice_date}
                        onChange={val => setDetails(d => ({ ...d, invoice_date: val, due_date: "" }))}
                        required={true}
                        maxDate={0}
                        minDate={(profile.permission_level === 1) ? false : moment().subtract(1, 'M').format("YYYY-MM-DD")}
                    />
                    <Feedback type="invalid">
                        The payment date should be defined.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Payment Due Date</Label>
                    <DateSelector
                        value={details.due_date}
                        onChange={val => setDetails(d => ({ ...d, due_date: val }))}
                        required={true}
                        minDate={details.invoice_date || 0}
                        maxDate={moment(details.invoice_date || null).add(12, 'M').format("YYYY-MM-DD")}
                    />
                    <Feedback type="invalid">
                        The due date should be defined.
                    </Feedback>
                </Col>
            </Row>
            {/* <Row>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Subject/Job Description</Label>
                    <Control
                        as="textarea"
                        value={details.subject}
                        onChange={e => setDetails(d => ({ ...d, subject: e.target.value }))}
                        rows={1}
                        onFocus={e => (e.target.rows = 4)}
                        onBlur={e => (e.target.rows = 2)}
                    />
                </Col>
            </Row> */}

            <Row>
                <Col sm={4} className="my-1">
                    <Label className='form-field-title'>Tax Inclusive or Exclusive</Label>
                    <Form.Check
                        className=""
                        type="switch"
                        id="isBillSwitch"
                        checked={details.isTaxInclusive}
                        onChange={() => setDetails(d => ({ ...d, isTaxInclusive: !d.isTaxInclusive }))}
                        label={details.isTaxInclusive ? "Prices are tax inclusive" : "Prices are tax exclusive"}
                    />
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Currency</Label>
                    <Form.Select
                        value={details.currency}
                        onChange={e => setDetails(d => ({ ...d, currency: e.target.value }))}
                        size="sm"
                    >
                        <option value="UGX">UGX</option>
                        <option value="USD">USD</option>
                    </Form.Select>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Exchange Rate</Label>
                    <Control
                        type="number"
                        value={details.exchange_rate}
                        onChange={e => setDetails(d => ({ ...d, exchange_rate: e.target.value }))}
                        size="sm"
                        required={details.currency !== "UGX"}
                    />
                    {currencyChanged &&
                        <Button variant='link' size="sm" onClick={handleCurrencyChange}>
                            Convert Now
                        </Button>
                    }

                </Col>
            </Row>

            <h4 className="form-section-label">Items</h4>

            <InvoiceItems
                invoiceitems={{ items, setItems }}
                deleteditems={{ setDeletedItems }}
                isTaxInclusive={details.isTaxInclusive}
                taxPercent={details.tax_percent}
                currency={details.currency}
            >
                <Select
                    size="sm"
                    className='rounded-pill'
                    items={pricelist_items}
                    maxItems={20}
                    onSelect={onPickItems}
                >
                    <i className='fas fa-store me-1' />Add Pricelist Item
                </Select>
            </InvoiceItems>

            <h4 className="form-section-label">Other Details</h4>

            <Row className="g-2">
                {/* <Col sm={4} className="my-1">
                    <Label className="form-field-title">Management Fees</Label>
                    <InputGroup>
                        <Control
                            type="number"
                            value={details.management_fee_value}
                            onChange={e => setDetails(d => ({ ...d, management_fee_value: e.target.value }))}
                            step={details.discount_type === "percent" ? 0.1 : 1}
                            max={details.discount_type === "percent" ? 50 : null}
                            size="sm"
                            style={{ flexGrow: 3 }}
                        />
                        <Form.Select
                            value={details.management_fee_type}
                            onChange={e => setDetails(d => ({ ...d, management_fee_type: e.target.value }))}
                            size="sm"
                        >
                            <option value="amount">{details.currency}</option>
                            <option value="percent">%</option>
                        </Form.Select>
                        <Feedback type="invalid">
                            Maximum for percent should be 50
                        </Feedback>
                    </InputGroup>
                </Col> */}
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Discount</Label>
                    <InputGroup>
                        <Control
                            type="number"
                            value={details.discount_value}
                            onChange={e => setDetails(d => ({ ...d, discount_value: e.target.value }))}
                            step={details.discount_type === "percent" ? 0.1 : 1}
                            max={details.discount_type === "percent" ? 50 : null}
                            size="sm"
                            style={{ flexGrow: 3 }}
                        />
                        <Form.Select
                            value={details.discount_type}
                            onChange={e => setDetails(d => ({ ...d, discount_type: e.target.value }))}
                            size="sm"
                        >
                            <option value="amount">{details.currency}</option>
                            <option value="percent">%</option>
                        </Form.Select>
                        <Feedback type="invalid">
                            Maximum for percent should be 50
                        </Feedback>
                    </InputGroup>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Tax</Label>
                    <Form.Select
                        value={details.tax_percent}
                        onChange={e => setDetails(d => ({ ...d, tax_percent: e.target.value }))}
                        size="sm"
                    >
                        <option value="0">No Tax</option>
                        {taxes.map(t => <option value={t.value} key={t.value}>{t.title}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        Maximum for percent should be 50
                    </Feedback>
                </Col>
            </Row>

            <h4 className="form-section-label">Service Terms</h4>
            <InvoiceNotes notes={tcs} setNotes={setTCs} />

            <h4 className="form-section-label">Notes</h4>
            <InvoiceNotes notes={notes} setNotes={setNotes} />


            <Row>
                <Col className="mt-3 mt-sm-5 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Invoice" : "Create Invoice"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting} onClick={() => navigate(-1)}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form >
    )
}



export default InvoiceForm;