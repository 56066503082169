import { useRef, useState } from "react";
import { Col, Row, Form, Modal } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../../components/btns";
import { errorAlert, successAlert } from "../../../components/toastr";
import { DateSelector } from "../../../components/datetimepicker";
import { ActionButton } from "../../../components/pageheader";
import { useEffect } from "react";
import { updateInvoiceEffris } from "../../../resources/api/invoices";

const { Label, Control, Group } = Form;

/**
 * 
 * @param {Object} props
 * @param {string} props.invoice_id ID of the invoice
 * @param {import("../../../resources/api/invoices").EffrisObject} props.effris_details
 * @param {(details: import("../../../resources/api/invoices").InvoiceObject) => void} props.onUpload
 */
const UploadEffris = ({ onUpload, effris_details, invoice_id }) => {

    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [handlingSubmit, setHandlingSubmit] = useState(false);

    const [date, setDate] = useState(effris_details.effris_date || "");
    const [fdn, setFDN] = useState(effris_details.effris_fdn || "");
    const [vc, setVC] = useState(effris_details.effris_vc || "");

    const ref = useRef();

    useEffect(() => {
        setDate(effris_details.effris_date || "");
        setFDN(effris_details.effris_fdn || "");
        setVC(effris_details.effris_vc || "");
    }, [effris_details.effris_date, effris_details.effris_fdn, effris_details.effris_vc])

    /**
     * 
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = ref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have some errors in your form.");
            return;
        }

        setValidated(false);
        setHandlingSubmit(true);

        updateInvoiceEffris({
            effris_date: date,
            effris_fdn: fdn,
            effris_vc: vc
        },
            invoice_id
        )
            .then(({ message, invoice }) => {
                successAlert(message);
                onUpload(invoice);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => {
                form.reset();
                setHandlingSubmit(false);
            })

    }

    const handleExit = () => {
        setValidated(false);
        setVC("");
        setDate("");
        setFDN("");
    }

    return (

        <>
            <ActionButton
                variant="outline-secondary"
                size="sm"
                title="Upload/Update Effris Details"
                onClick={() => setShow(true)}
            >
                <i className="fas fa-file-invoice" />
            </ActionButton>

            <Modal show={show} onExit={handleExit} centered size="sm">
                <Modal.Body>
                    <Form noValidate validated={validated} ref={ref}>

                        <Row>
                            <Col sm={12} className="my-1">
                                <Group controlId="category">
                                    <Label className="form-field-title">Date</Label>
                                    <DateSelector
                                        value={date}
                                        onChange={d => setDate(d || "")}
                                        required={(!!vc || !!fdn)}
                                    />
                                    <Control.Feedback type="invalid">
                                        Effris Date is required or leave both fields empty.
                                    </Control.Feedback>
                                </Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="my-1">
                                <Label className="form-field-title">VC</Label>
                                <Control
                                    required={(!!date || !!fdn)}
                                    value={vc}
                                    onChange={e => setVC(e.currentTarget.value)}
                                />
                                <Control.Feedback type="invalid">
                                    VC number is required or leave both fields empty.
                                </Control.Feedback>

                            </Col>
                        </Row>

                        <Row>
                            <Col className="my-1">
                                <Label className="form-field-title">FDN</Label>
                                <Control
                                    required={(!!date || !!vc)}
                                    value={fdn}
                                    onChange={e => setFDN(e.currentTarget.value)}
                                />
                                <Control.Feedback type="invalid">
                                    FDN number is required or leave both fields empty.
                                </Control.Feedback>

                            </Col>
                        </Row>



                        <Row className="mt-3 text-end">
                            <Col>
                                <SubmitButton
                                    onClick={handleSubmit}
                                    isSubmitting={handlingSubmit}
                                >
                                    Update
                                </SubmitButton>
                                <CancelButton
                                    isSubmitting={handlingSubmit}
                                    onClick={() => setShow(false)}
                                >
                                    Cancel
                                </CancelButton>
                            </Col>
                        </Row>
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}


export { UploadEffris };