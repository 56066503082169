import { Form, Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import { error as errorAlert, successAlert } from '../../components/toastr';
import { SubmitButton, CancelButton } from '../../components/btns';
// import SelectInput from '../../components/select';
// import { firmRoles } from '../../assets/constants';
import { createUser, updateUser } from '../../resources/api/users';

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('./view-users').UserObject} props.details
 * @param {(details: import('./view-users').UserObject) => void} props.onSubmit
 */
const UserForm = ({ details: userObject, action, onSubmit }) => {


    const [details, setDetails] = useState(userObject);
    // const [roles, setRoles] = useState([]);
    // const [rms, setRMs] = useState([]);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);


    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        let promise;

        if (action === "create")
            promise = createUser({ ...details });
        else
            promise = updateUser({ ...details }, details.id);


        promise
            .then(({ message, user }) => {
                successAlert(message);
                onSubmit(user);
            })
            .catch(err => {
                errorAlert(err);
                setSubmitting(false);
            })

    }

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Personal Details</h4>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Other Names</Label>
                    <Control
                        value={details.other_names}
                        onChange={e => setDetails({ ...details, other_names: e.currentTarget.value })}
                        // placeholder="e.g., United Nations"
                        required={true}
                    />
                    <Feedback type="invalid">
                        This field is required
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Last Name</Label>
                    <Control
                        value={details.last_name}
                        onChange={e => setDetails({ ...details, last_name: e.currentTarget.value })}
                        pattern="a-zA-Z0-9\'"
                        required={true}
                    />
                    <Feedback type="invalid">
                        Last name is required.
                    </Feedback>
                </Col>
            </Row>

            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Email Address</Label>
                    <Control
                        value={details.email}
                        onChange={e => setDetails({ ...details, email: e.currentTarget.value })}
                        type="email"
                        required={true}
                    />
                    <Feedback type="invalid">
                        A valid email address must be provided.
                    </Feedback>
                    <Form.Text muted>
                        This will be used to log in.
                    </Form.Text>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Telephone</Label>
                    <Control
                        value={details.telephone}
                        onChange={e => setDetails({ ...details, telephone: e.currentTarget.value })}
                        placeholder='e.g., +256-782-748362'
                        pattern="^(\+)?[1-9]{1}[0-9]{0,2}(-|\s)?[0-9]{3,4}(-|\s)?[0-9]{3}(-|\s)?[0-9]{3}$"
                        required={true}
                    />
                    <Feedback type="invalid">
                        A valid telephone number must be provided.
                    </Feedback>
                </Col>
            </Row>

            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Job Title</Label>
                    <Control
                        value={details.job_title}
                        onChange={e => setDetails({ ...details, job_title: e.currentTarget.value })}
                        required
                    />
                    <Feedback type="invalid">
                        This field is required.
                    </Feedback>
                </Col>
            </Row>

            {(action !== "edit") &&
                <Row className="g-2">
                    {/* <Col sm={6} className="my-1">
                        <Label className="form-field-title">Roles</Label>
                        <SelectInput
                            value={roles}
                            onChange={_roles => setRoles(_roles)}
                            options={firmRoles.map(r => ({ id: r, title: r }))}
                            maxItems={firmRoles.length}
                            required
                        />
                    </Col> */}
                    <Col sm={6}>
                        <Label className="form-field-title">Permission Level</Label>
                        <Control
                            type="number"
                            value={details.permission_level}
                            onChange={e => setDetails({ ...details, permission_level: e.currentTarget.value })}
                            required
                        />
                        <Feedback type="invalid">
                            This field is required.
                        </Feedback>
                    </Col>
                </Row>
            }

            <Row>
                <Col className="mt-5 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update User" : "Create User"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}



export default UserForm;