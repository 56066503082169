import { Routes, Route } from 'react-router-dom';
import { useContext } from "react";
import { AppUserContext } from "../../App";
import { ErrorLoading } from "../../components/loading";
import ViewInventory from './view-inventory';
import { NewInventory } from './inventory-manipulation';
import InventoryItemDetails from './invetory-details';

const Inventory = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 3) {
        return <ErrorLoading>You do not have the permission to access clients.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/" element={<ViewInventory />} />
            <Route path="/new" element={<NewInventory />} />
            <Route path="/:itemid/*" element={<InventoryItemDetails />} />
        </Routes>
    )
}

export default Inventory;