import { Form, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import { errorAlert, successAlert } from '../../components/toastr';
import { SubmitButton, CancelButton } from '../../components/btns';
import { useNavigate } from 'react-router-dom';
import { InventoryCategories, InventoryUnits } from '../../assets/constants';
import { UCWords } from '../../components/resources';
import { createItem, updateItem } from '../../resources/api/inventory';

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('../../resources/api/inventory').InventoryItem} props.details
 * @param {(details: import('../../resources/api/inventory').InventoryItem) => void} props.onSubmit
 */
const InventoryForm = ({ details: inventoryItem, action, onSubmit }) => {


    const [details, setDetails] = useState(inventoryItem);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        const promise = (action === "edit") ? updateItem(details, details.id) : createItem(details);

        promise
            .then(({ item, message }) => {
                successAlert(message);
                onSubmit(item);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Name</Label>
                    <Control
                        value={details.name}
                        minLength={8}
                        maxLength={60}
                        onChange={e => setDetails({ ...details, name: e.currentTarget.value })}
                        required={true}
                    />
                    <Feedback type="invalid">
                        The title should have a minimum of 8 and maximum of 60 characters.
                    </Feedback>
                    <Form.Text muted>
                        Name is required
                    </Form.Text>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Category</Label>
                    <Form.Select
                        value={details.category}
                        onChange={e => setDetails({ ...details, category: e.currentTarget.value })}
                        required={true}
                    >
                        <option value=""></option>
                        {InventoryCategories.map(c => <option key={c} value={c}>{(UCWords(c.replace(/-/g, " ")))}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        Categorise this item.
                    </Feedback>
                </Col>
            </Row>
            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Unit</Label>
                    <Form.Select
                        value={details.unit}
                        onChange={e => setDetails({ ...details, unit: e.currentTarget.value })}
                        required={true}
                    >
                        <option value=""></option>
                        {InventoryUnits.map(c => <option key={c} value={c}>{(UCWords(c.replace(/-/g, " ")))}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        State the unit this item is dealt in.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Opening Units</Label>
                    <Control
                        value={details.opening_units}
                        type="number"
                        onChange={e => setDetails({ ...details, opening_units: e.currentTarget.value })}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Description</Label>
                    <Control
                        as="textarea"
                        value={details.description}
                        onChange={e => setDetails(d => ({ ...d, description: e.target.value }))}
                        rows={1}
                        onFocus={e => (e.target.rows = 4)}
                        onBlur={e => (e.target.rows = 2)}
                    />
                </Col>
            </Row>

            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Item" : "Create Item"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting} onClick={() => navigate(-1)}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}


export default InventoryForm;