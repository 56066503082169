
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { AdjustmentTypes } from "../../assets/constants";
import { CancelButton, SubmitButton } from "../../components/btns";
// import cur from "../../components/currency";
import { DateSelector } from "../../components/datetimepicker";
import { UCWords } from "../../components/resources";
import SelectInput from "../../components/select";
import { errorAlert, successAlert } from "../../components/toastr";
// import { getExpenses } from "../../resources/api/expenses";
import { createPurchase, updatePurchase } from "../../resources/api/inventory-purchases";
import { createAdjustment, updateAdjustment } from "../../resources/api/inventory-adjustments";
import { getJobs } from "../../resources/api/jobs";

const { Control, Label } = Form;


/**
 * @param {Object} props
 * @param {"create"|"user"|"salary"} props.action
 * @param {(details: import("../../resources/api/inventory").PurchaseItem) => void} props.onUpload
 * @param {{
*  purcahse_show: boolean
*  setPurchaseShow: React.Dispatch<React.SetStateAction<boolean>>
* }} props.show
* @param {{
*  item_details: import("../../resources/api/inventory").PurchaseItem
*  setItemDetails: React.Dispatch<React.SetStateAction<import("../../resources/api/inventory").PurchaseItem>>
* }} props.purchaseDetails
*/
const PurchaseForm = ({ onUpload, action = "create", purchaseDetails, show: toShow, item_id }) => {

    const inputref = useRef();

    const { purcahse_show: show, setPurchaseShow: setShow } = toShow;
    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [validated, setValidated] = useState(false);

    // const [isLoaded, setLoaded] = useState(false);
    // const [expenses, setExpenses] = useState([]);

    const { item_details: details, setItemDetails: setDetails } = purchaseDetails;

    // useEffect(() => {

    //     if (!show) return;
    //     if (isLoaded) return;

    //     getExpenses()
    //         .then(({ expenses }) => {
    //             setExpenses(expenses.map(e => ({
    //                 ...e,
    //                 title: e.reference,
    //                 description: `${moment(e.expense_date).format("DD MMM YYYY")} | ${cur(e.total_amount, 0).format()}`
    //             })))
    //         })
    //         .catch(e => errorAlert(`Failed to load expenses. ${e}`))

    //     setLoaded(true);

    // }, [isLoaded, show])



    /**
     * Handles the submission of the form
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = inputref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("Some errors have been detected in the form. These have been highlighted for you.", "Invalid Fields");
            return;
        }

        setHandlingSubmit(true);
        setValidated(false);

        let promise;

        if (action === "create") {
            promise = createPurchase({ ...details, item_id });
        } else {
            promise = updatePurchase(details, details.id);
        }

        // promise = Promise.resolve();

        promise
            .then(({ purchase, message }) => {
                form.reset();
                successAlert(message);
                onUpload(purchase, action);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => setHandlingSubmit(false))
    }

    return (
        <Modal show={show} onExit={() => setValidated(false)} centered animation={false} size="sm">
            <Modal.Header className="bg-secondary text-light">
                <h4 className="mb-0">Add Purchase</h4>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} ref={inputref}>

                    {(action !== "salary") &&
                        <>
                            <Row className="g-1">
                                <Col sm={12} className="my-1">
                                    <Label className="form-field-title">Delivery Date</Label>
                                    <DateSelector
                                        minDate={moment().add(-1, 'months').format('YYYY-MM-DD')}
                                        maxDate={0}
                                        value={details.delivery_date}
                                        onChange={val => setDetails(d => ({ ...d, delivery_date: val }))}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        You must select an expense date.
                                    </Control.Feedback>
                                </Col>
                                <Col sm={12} className="my-1">
                                    <Label className="form-field-title">Quantity</Label>
                                    <Control
                                        value={details.quantity}
                                        type="number"
                                        onChange={e => setDetails({ ...details, quantity: e.currentTarget.value })}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        Put quantity
                                    </Control.Feedback>
                                </Col>
                            </Row>
                            {/* <Row className="g-1">
                                <Col sm={12} className="my-1">
                                    <Label className="form-field-title">Expense</Label>
                                    <SelectInput
                                        value={[details.expense_id]}
                                        maxItems={1}
                                        options={expenses}
                                        onChange={([id]) => setDetails(d => ({ ...d, expense_id: id || "" }))}
                                        placeholder="-"
                                        
                                        errorText="An expense must be attached to this purchase"
                                    />
                                </Col>
                            </Row> */}
                        </>
                    }


                    <Row>
                        <Col className="mt-4 mb-0 text-end">
                            <SubmitButton isSubmitting={handlingSubmit} onClick={handleSubmit}>
                                {action === "edit" ? "Update" : "Submit"}
                            </SubmitButton>
                            <CancelButton isSubmitting={handlingSubmit} onClick={() => setShow(false)}>
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}


/**
 * @param {Object} props
 * @param {"create"|"user"|"salary"} props.action
 * @param {(details: import("../../resources/api/inventory").AdjustmentObject) => void} props.onUpload
 * @param {{
*  adjustmentShow: boolean
*  setAdjustmentShow: React.Dispatch<React.SetStateAction<boolean>>
* }} props.show
* @param {{
*  aitem_details: import("../../resources/api/inventory").AdjustmentObject
*  setAItemDetails: React.Dispatch<React.SetStateAction<import("../../resources/api/inventory").AdjustmentObject>>
* }} props.purchaseDetails
*/
const AdjustmentForm = ({ onUpload, action = "create", purchaseDetails, show: toShow, item_id }) => {

    const inputref = useRef();

    const { adjustmentShow: show, setAdjustmentShow: setShow } = toShow;
    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [validated, setValidated] = useState(false);

    const { aitem_details: details, setAItemDetails: setDetails } = purchaseDetails;

    const [isLoaded, setLoaded] = useState(false);
    const [jobs, setJobs] = useState([]);



    useEffect(() => {

        if (!show) return;
        if (isLoaded) return;

        getJobs()
            .then(({ jobs }) => {
                setJobs(jobs.map(e => ({
                    ...e,
                    title: `${e.title} - ${e.job_no}`,
                    description: `${moment(e.job_date).format("DD MMM YYYY")} | ${e.location}`
                })))
            })
            .catch(e => errorAlert(`Failed to load jobs. ${e}`))

        setLoaded(true);

    }, [isLoaded, show])



    /**
     * Handles the submission of the form
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = inputref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("Some errors have been detected in the form. These have been highlighted for you.", "Invalid Fields");
            return;
        }

        setHandlingSubmit(true);
        setValidated(false);

        let promise;

        if (action === "create") {
            promise = createAdjustment({ ...details, item_id });
        } else {
            promise = updateAdjustment(details, details.id);
        }

        // promise = Promise.resolve();

        promise
            .then(({ adjustment, message }) => {
                form.reset();
                successAlert(message);
                onUpload(adjustment, action);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => setHandlingSubmit(false))
    }

    return (
        <Modal show={show} onExit={() => setValidated(false)} centered animation={false} size="sm">
            <Modal.Header className="bg-secondary text-light">
                <h4 className="mb-0">Add Adjustment</h4>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} ref={inputref}>


                    <Row className="g-1">
                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">Adjustment Date</Label>
                            <DateSelector
                                minDate={moment().add(-1, 'months').format('YYYY-MM-DD')}
                                maxDate={0}
                                value={details.adjustment_date}
                                onChange={val => setDetails(d => ({ ...d, adjustment_date: val }))}
                                required
                            />
                            <Control.Feedback type="invalid">
                                You must select an adjustment date.
                            </Control.Feedback>
                        </Col>
                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">Quantity</Label>
                            <Control
                                value={details.quantity}
                                type="number"
                                onChange={e => setDetails({ ...details, quantity: e.currentTarget.value })}
                                required
                            />
                            <Control.Feedback type="invalid">
                                Put quantity
                            </Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="g-1">
                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">Adjustment Type</Label>
                            <Form.Select
                                value={details.adjustment_type}
                                onChange={e => setDetails({ ...details, adjustment_type: e.currentTarget.value })}
                                required
                            >
                                <option></option>
                                {AdjustmentTypes.map(c => <option key={c} value={c}>{(UCWords(c.replace(/-/g, " ")))}</option>)}

                            </Form.Select>
                            <Control.Feedback type="invalid">
                                Required
                            </Control.Feedback>
                        </Col>
                    </Row>
                    <Row className="g-1">
                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">Job</Label>
                            <SelectInput
                                value={[details.job_id]}
                                maxItems={1}
                                options={jobs}
                                onChange={([id]) => setDetails(d => ({ ...d, job_id: id || "" }))}
                                placeholder="-"
                                required={details.adjustment_type === "loss"}
                                errorText="A job must be attached to this adjustment"
                            />
                        </Col>
                    </Row>


                    <Row>
                        <Col className="mt-4 mb-3 text-end">
                            <SubmitButton isSubmitting={handlingSubmit} onClick={handleSubmit}>
                                {action === "edit" ? "Update" : "Submit"}
                            </SubmitButton>
                            <CancelButton isSubmitting={handlingSubmit} onClick={() => setShow(false)}>
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal >
    )
}


export { PurchaseForm, AdjustmentForm }